import React from "react";
import { useLocation } from "react-router-dom";
import styles from "./video.module.scss";
import AppStateProvider from "./state";
import VideoApp from "./VideoApp";
const VideoChat = () => {
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const name = new URLSearchParams(search).get("name");
  return (
    <div className={styles.videoContainer}>
        <AppStateProvider>
          <VideoApp name={name} nurseId={id} loader={true} ></VideoApp>
        </AppStateProvider>
    </div>
  );
};

export default VideoChat;
