import React, { useState, useEffect } from "react";

import RowUnselectedIcon from "../../assets/icons/row-unselected.svg";
import RowSelectedIcon from "../../assets/icons/row-selected.svg";

const SelectRow = ({ selected, onClick, style }) => {
  const [isSelected, setSelected] = useState(false);

  useEffect(() => {
    setSelected(selected);
  }, [selected]);
  return (
    <div
      style={style}
      className="cursor-pointer"
      onClick={() => {
        setSelected(!isSelected);

        if (onClick) {
          onClick();
        }
      }}
    >
      <img
        src={isSelected ? RowSelectedIcon : RowUnselectedIcon}
        style={{ height: 24, width: 24 }}
      />
    </div>
  );
};

export default SelectRow;
