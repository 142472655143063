import React, { Component } from "react";
import Modal from "@material-ui/core/Modal";
import { PrimaryButton, SecondaryButton, InputField } from "../../components";
import { Grid } from "@material-ui/core";

export class FeedbackInput extends Component {
  state = {
    open: false,
    title: this.props.title.length ? this.props.title : "",
    description: this.props.description ? this.props.description : "",
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleChange = (e, type) => {
    this.setState({
      [type]: e.target.value,
    });
  };

  render() {
    return (
      <Modal
        className="modal-container"
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        onBackdropClick={this.props.handleClose}
      >
        <div className="modal-content">
          <div className="modal-icon"></div>
          <div className="modal-heading">{this.props.heading} Note!</div>

          <Grid container justify="center">
            <Grid item xs={10} style={{ paddingTop: "10px", marginBottom: 20 }}>
              <InputField
                id="feedback"
                type="text"
                multiline
                rows={3}
                inputProps={{ maxLength: 150 }}
                label="Title"
                variant="outlined"
                value={this.state.title}
                onChange={(e) => this.handleChange(e, "title")}
                helperText={`${this.state.title.length}/150`}
                heleperTextRight
                fullWidth

              />
            </Grid>
            <Grid item xs={10}>
              <InputField
                id="Description"
                type="text"
                multiline
                rows={3}
                inputProps={{ maxLength: 250 }}
                label="Provide your valuable Input"
                variant="outlined"
                value={this.state.description}
                onChange={(e) => this.handleChange(e, "description")}
                helperText={`${this.state.description.length}/250`}
                heleperTextRight
                fullWidth
              />
            </Grid>
          </Grid>

          <div className="modal-cta" style={{ marginTop: "40px" }}>
            <SecondaryButton
              variant="contained"
              style={{ height: 54, marginRight: 16 }}
              onClick={this.props.handleClose}
            >
              Cancel
            </SecondaryButton>
            {this.state.title.trim().length > 0 && this.state.description.trim().length > 0 && (
              <PrimaryButton
                variant="contained"
                color="primary"
                style={{ height: 54 }}
                onClick={() => this.props.confirm(this.state)}
              >
                {this.props.heading === "Add" ? "Add" : "Update"}
              </PrimaryButton>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

export default FeedbackInput;
