import { combineReducers } from "redux";

import uiReducer from "./uiReducer";
import authReducer from "./authReducer";
import adminReducer from "./adminReducer";
import twilioReducer from "./twilioReducer"

export default combineReducers({
  ui: uiReducer,
  auth: authReducer,
  admin: adminReducer,
  twilio:twilioReducer,
});