import React from "react";
import Modal from "@material-ui/core/Modal";
import {
  PrimaryButton,
  SecondaryButton,
  InputField,
  Loader,
  SsnInputField,
  Datepicker,
  PhoneInputField,
} from "../../components";
import TooltipRef from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { addDays } from "date-fns";
import Dropzone from "react-dropzone";
import UploadDocumentIcon from "../../assets/icons/UploadModalIcon@3x.svg";
import ThumbnailPlaceHolder from "../../assets/icons/thumbnail-placeholder.svg";
import { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CloseIcon from "../../assets/icons/cross.svg";
import deleteIcon from "../../assets/icons/delete.svg";
import { Toast } from "../../components";
import moment from "moment";
import { Axios } from "../../../api/apiConsts";
import ImagePreviewUpdate from "../ImagePreviewCred";
import PDFUploaded from "../../assets/images/pdf-uploaded.svg";
import DOCUploaded from "../../assets/images/doc-uploaded.svg";

const useStyles = makeStyles({
  uploadDocumentContainer: {
    padding: "0px 0px 0px 20px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  removedFileContainer: {
    display: "flex",
    marginLeft: "15px",
    marginTop: "-7px",
  },
  imageStyle: {
    alignSelf: "flex-start",
    marginLeft: "-10px",
    marginTop: "-10px",
  },
  reftitle: {
    marginBottom: 10,
    display: "flex",
  },
  refdelete: {
    height: 25,
    marginLeft: "10px",
    marginTop: -3,
  },
  textDocumentsContainer: {
    width: "80%",
  },
  expDocumentsContainer: {
    width: "54%",
  },
  ssnInput: {
    marginRight: "0px",
  },
  textContainer: {
    marginRight: "0",
  },
  uploadDocuments: {
    display: "flex",
    paddingLeft: "15px",
  },
  textdocs: {
    display: "flex",
    paddingLeft: "34px",
    marginTop: "15px",
  },
  grid: {
    marginBottom: 10,
    flexWrap: "nowrap",
  },
  paddingGrid: {
    padding: "0px !important",
    marginRight: 5,
  },
  marginGrid: {
    marginRight: 5,
    marginTop: 8,
    padding: 0,
  },
});

const UploadCredentials = ({
  credential_data,
  id,
  handleClose,
  employeeID,
  heading,
  getCredentials,
  open,
  extensions,
}) => {
  const [loading, setloading] = useState(false);
  const [textData, setTextData] = useState("");
  const [updatedTextCred, setupdatedTextCred] = useState([]);
  const [updatedExpiryCred, setupdatedExpiryCred] = useState([]);

  const [expiryDate, setexpiryDate] = useState(credential_data[id].expiry);
  const [documents, setDocuments] = useState(
    credential_data[id].credential_data
  );
  const [docId, setdocId] = useState(credential_data[id].id);
  let doc = [];
  documents.forEach((cred) => {
    {
      doc.push({
        id: cred.id,
        file: cred.file,
        data_type: cred.data_type,
        upload_data: cred.upload_data,
      });
    }
  });
  const [credentialUpdated, setcredentialUpdated] = useState(doc);
  const [refArray, setRefarray] = useState([]);
  useEffect(() => {
    let ref = [];
    for (let i = 0; i < documents[0].reference_count; i++) {
      let obj = {
        name: documents[0].reference_data[i]?.name
          ? documents[0].reference_data[i].name
          : "",
        email: documents[0].reference_data[i]?.email
          ? documents[0].reference_data[i].email
          : "",
        mobile: documents[0].reference_data[i]?.mobile
          ? documents[0].reference_data[i].mobile
          : "",
        relationship: documents[0].reference_data[i]?.relationship
          ? documents[0].reference_data[i].relationship
          : "",
        errorName: false,
        nameHelperText: "",
        errorEmail: false,
        emailHelperText: "",
        errorMobile: false,
        mobileHelperText: "",
        errorRelationship: false,
        relationshipHelperText: "",
      };
      ref.push(obj);
    }
    setRefarray(ref);
  }, []);

  let text_index;
  useEffect(() => {
    for (let i = 0; i < documents.length; i++) {
      if (documents[i].data_type == "TEXT") {
        text_index = i;
        setTextData(documents[text_index].text_data);
        break;
      }
    }
  }, []);

  const handleDateChange = (date, docID, index, data_type, expiry) => {
    setexpiryDate(date);
    setexpiryDate(date);
    let arr = [...updatedExpiryCred];
    arr[index] = {
      ...updatedExpiryCred[index],
      docId: docID,
      data_type: "Expiry",
      expiry: moment(date).format("YYYY-MM-DD"),
    };
    setupdatedExpiryCred(arr);
  };

  const removeDocument = (e, uploadId, index, data_type) => {
    let arr = [...credentialUpdated];
    arr[index] = { id: uploadId, file: "removed", data_type: data_type };
    setcredentialUpdated(arr);
  };

  const closehandle = () => {
    setDocuments(credential_data);
    handleClose();
  };

  const docchandler = (uploadId, acceptedFiles, index, data_type) => {
    let arr = [...credentialUpdated];
    arr[index] = {
      id: uploadId,
      upload_data: "ned",
      file: acceptedFiles[0],
      data_type: data_type,
    };
    setcredentialUpdated(arr);
  };

  const handltext = (e, index, uploadId, data_type) => {
    let arr = [...updatedTextCred];
    arr[index] = {
      id: uploadId,
      file: e.target.value,
      data_type: data_type,
      expiry: updatedTextCred[index]?.expiry,
    };
    setupdatedTextCred(arr);
    setTextData(e.target.value);
  };

  const pushTextDocs = async (updatedTextCred, string = "") => {
    return new Promise((resolve, reject) => {
      if (updatedTextCred?.file) {
        const packet = new FormData();
        packet.append("text_data", updatedTextCred.file);
        if (string === "last") {
          packet.append("is_completed", true);
        }
        Axios.patch(`nurse/credentials/${updatedTextCred.id}`, packet)
          .then((response) => {
            if (response.data.statusCode === 200) {
              resolve();
            }
          })
          .catch((error) => {
            reject();
          });
      } else {
        setloading(false);
        Toast.showErrorToast(
          "Document associated with expiry field can't be null"
        );
        handleClose();
        return;
      }
    });
  };

  const pushExpiry = async (updatedExpiryCred, string = "") => {
    return new Promise((resolve, reject) => {
      const packet = {
        expiry: moment(updatedExpiryCred[0].expiry).format("YYYY-MM-DD"),
      };
      if (string === "last") {
        packet["is_completed"] = true
        
      }
      Axios.patch(
        `nurse/${employeeID}/credential/expiry/${updatedExpiryCred[0].docId}`,
        packet
      )
        .then((response) => {
          if (response.data.statusCode === 200) {
            resolve();
          }
        })
        .catch((error) => {
          reject();
        });
    });
  };

  const pushUploadDocs = (credentialUpdated, string = "") => {
    return new Promise((resolve, reject) => {
      const packet = new FormData();
      packet.append(
        "upload_data",
        credentialUpdated.file == "removed" ? "" : credentialUpdated.file
      );
      if (string === "last") {
        packet.append("is_completed", true);
      }
      Axios.patch(`nurse/credentials/${credentialUpdated.id}`, packet)
        .then((response) => {
          if (response.data.statusCode === 200) {
            resolve();
          }
        })
        .catch((error) => {
          reject();
        });
    });
  };

  const pushRefrences = (refID) => {
    let arr = refArray.filter((ref) => {
      if (ref.name !== "") {
        return ref;
      }
    });
    return new Promise((resolve, reject) => {
      const packet = new FormData();
      packet.append("reference_data", JSON.stringify(arr));
      Axios.patch(`nurse/credentials/${refID}`, packet)
        .then((response) => {
          if (response.data.statusCode === 200) {
            resolve();
          }
        })
        .catch((error) => {
          reject();
        });
    });
  };

  const pushCredentials = async () => {
    setloading(true);
    let i = 0;
    let promiseArray = [];
    let isCompletedAdded = true;
    let string = "";
    for (i = 0; i < credentialUpdated.length; i++) {
      //for text type
      if (
        credentialUpdated[i].data_type === "TEXT" &&
        (updatedTextCred[i]?.file || updatedTextCred[i]?.file === "")
      ) {
        if (isCompletedAdded) {
          string = "last";
          isCompletedAdded = false;
        }
        promiseArray.push(pushTextDocs(updatedTextCred[i], string));
        string = "";
      }
      //for upload type
      if (
        credentialUpdated[i].data_type === "UPLOAD" &&
        credentialUpdated[i].file
      ) {
        if (isCompletedAdded) {
          string = "last";
          isCompletedAdded = false;
        }
        promiseArray.push(pushUploadDocs(credentialUpdated[i], string));
        string = "";
      }
      if (credential_data[id].expiry_required && updatedExpiryCred[i]?.expiry) {
        if (isCompletedAdded) {
          string = "last";
          isCompletedAdded = false;
        }
        promiseArray.push(pushExpiry(updatedExpiryCred, string));
        string = "";
      }
    }
    if (documents[0].data_type == "REFERENCE") {
      promiseArray.push(pushRefrences(documents[0].id));
    }
    if (promiseArray.length > 0) {
      Promise.all(promiseArray)
        .then(() => {
          handleClose();
          Toast.showInfoToast("Credential uploaded successfully");
          getCredentials();
        })
        .catch((err) => {
          handleClose();
          Toast.showErrorToast("Error in uploading credential");
          getCredentials();
        });
    } else {
      handleClose();
      //Toast.showInfoToast("Credential successfully");
    }
  };

  let initialUploadType = {
    jpg: ".jpg, .jpeg",
    png: ".png, .PNG",
    pdf: ".pdf, .PDF",
    doc: ".doc, .docx",
  };
  const uploadTypes = (arr) => {
    let finalStr = [];
    if (arr) {
      arr.map((item) => {
        finalStr.push(initialUploadType[item]);
      });
    }
    return finalStr.join();
  };

  const preview = (image) => {
    let icon = "";
    if (
      image.type === "image/jpg" ||
      image.type === "image/png" ||
      image.type === "image/jpeg" ||
      image.type === "image/PNG"
    ) {
      icon = null;
    } else if (image.type === "application/pdf") {
      icon = PDFUploaded;
    } else if (
      image.type === "application/msword" ||
      image.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      icon = DOCUploaded;
    }
    return icon;
  };

  const nameChangeHandler = (event, credIndex) => {
    const references = [...refArray];
    references[credIndex].name = event.target.value;
    setRefarray(references);
  };

  const nameErrorHandler = (event, credIndex) => {
    const references = [...refArray];
    if (event.target.value === "") {
      references[credIndex].errorName = true;
      references[credIndex].nameHelperText = "Name is required";
      setRefarray(references);
    } else {
      references[credIndex].errorName = false;
      references[credIndex].nameHelperText = "";
      setRefarray(references);
    }
  };

  const emailChangeHandler = (event, credIndex) => {
    const references = [...refArray];
    references[credIndex].email = event.target.value;
    setRefarray(references);
  };
  const emailErrorHandler = (event, credIndex) => {
    const references = [...refArray];
    if (
      refArray.some((ref, index) => {
        if (index !== credIndex) {
          return ref.email === event.target.value && event.target.value !== "";
        }
      })
    ) {
      references[credIndex].errorEmail = true;
      references[credIndex].emailHelperText = "Email already exists";
      setRefarray(references);
    } else {
      let regexEmail = /\S+@\S+\.\S+/;
      if (event.target.value.match(regexEmail)) {
        references[credIndex].errorEmail = false;
        references[credIndex].emailHelperText = "";
        setRefarray(references);
      } else {
        references[credIndex].errorEmail = true;
        references[credIndex].emailHelperText = "Please enter a valid email";
        setRefarray(references);
      }
    }
    if (references[credIndex].email === "") {
      references[credIndex].errorEmail = true;
      references[credIndex].emailHelperText = "Email is required";
      setRefarray(references);
    }
  };
  const getPreview = (type, link) => {
    let icon = "";
    if (type === "pdf") {
      icon = PDFUploaded;
    } else if (type === "doc" || type === "docx") {
      icon = DOCUploaded;
    }
    return icon;
  };
  const relationshipChangeHandler = (event, credIndex) => {
    const references = [...refArray];
    references[credIndex].relationship = event.target.value;
    setRefarray(references);
  };
  const relationshipErrorHandler = (event, credIndex) => {
    const references = [...refArray];
    if (event.target.value === "") {
      references[credIndex].errorRelationship = true;
      references[credIndex].relationshipHelperText = "Relationship is required";

      setRefarray(references);
    } else {
      references[credIndex].errorRelationship = false;
      references[credIndex].relationshipHelperText = "";
      setRefarray(references);
    }
  };

  const mobileChangeHandler = (event, credIndex) => {
    const references = [...refArray];
    references[credIndex].mobile = event.target.value.slice(2);
    setRefarray(references);
  };

  const mobileErrorHandler = (event, credIndex) => {
    const references = [...refArray];
    if (references[credIndex].mobile === "") {
      references[credIndex].errorMobile = true;
      references[credIndex].mobileHelperText = "Phone Number is required";
      setRefarray(references);
    } else {
      if (
        refArray.some((ref, index) => {
          if (index !== credIndex) {
            return ref.mobile == event.target.value.slice(2);
          }
        })
      ) {
        references[credIndex].errorMobile = true;
        references[credIndex].mobileHelperText = "Phone number already exist";
        setRefarray(references);
      } else {
        let regexMob = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
        if (event.target.value.slice(2).match(regexMob)) {
          references[credIndex].errorMobile = false;
          references[credIndex].mobileHelperText = "";
          setRefarray(references);
        } else {
          references[credIndex].errorMobile = true;
          references[credIndex].mobileHelperText =
            "Please enter a valid phone number";
          setRefarray(references);
        }
      }
    }
  };

  const deleteRefrence = (index) => {
    const references = [...refArray];

    references[index] = {
      name: "",
      email: "",
      mobile: "",
      relationship: "",
      errorName: false,
      nameHelperText: "",
      errorEmail: false,
      emailHelperText: "",
      errorMobile: false,
      mobileHelperText: "",
      errorRelationship: false,
      relationshipHelperText: "",
    };

    setRefarray(references);
  };

  const getRefCred = (cred) => {
    return refArray.map((ref, i) => (
      <div
        className="ref-card"
        style={{ paddingLeft: "30px", paddingRight: "30px" }}
      >
        <div className={classes.reftitle}>
          Reference {i + 1}{" "}
          {refArray[i].name && (
            <img
              className={classes.refdelete}
              onClick={() => deleteRefrence(i)}
              src={deleteIcon}
            />
          )}
        </div>
        <Grid container className={classes.grid}>
          <Grid item sm={4} md={3} className={classes.marginGrid}>
            <InputField
              id={`name ${i}`}
              style={{ minHeight: "0px" }}
              type="text"
              error={refArray[i]?.errorName}
              helperText={refArray[i]?.nameHelperText}
              label="Name"
              variant="outlined"
              value={refArray[i]?.name}
              onChange={(event) => nameChangeHandler(event, i)}
              onBlur={(event) => nameErrorHandler(event, i)}
              fullWidth
            />
          </Grid>
          <Grid sm={4} md={3} className={classes.marginGrid}>
            <PhoneInputField
              id={`phone ${i}`}
              style={{ minHeight: "0px" }}
              type="text"
              label="Phone Number"
              variant="outlined"
              value={refArray[i]?.mobile}
              error={refArray[i]?.errorMobile}
              helperText={refArray[i]?.mobileHelperText}
              onChange={(event) => mobileChangeHandler(event, i)}
              onBlur={(event) => mobileErrorHandler(event, i)}
              fullWidth
            />
          </Grid>
          <Grid sm={4} md={3} className={classes.marginGrid}>
            <InputField
              id={`email ${i}`}
              type="text"
              style={{ minHeight: "0px" }}
              label="Email Address"
              variant="outlined"
              value={refArray[i]?.email}
              error={refArray[i]?.errorEmail}
              helperText={refArray[i]?.emailHelperText}
              onChange={(event) => emailChangeHandler(event, i)}
              onBlur={(event) => emailErrorHandler(event, i)}
              fullWidth
            />
          </Grid>
          <Grid sm={4} md={3} className={classes.marginGrid}>
            <InputField
              id={`relationship ${i}`}
              style={{ minHeight: "0px" }}
              type="text"
              label="Relationship"
              variant="outlined"
              value={refArray[i]?.relationship}
              error={refArray[i]?.errorRelationship}
              helperText={refArray[i]?.relationshipHelperText}
              onChange={(event) => relationshipChangeHandler(event, i)}
              onBlur={(event) => relationshipErrorHandler(event, i)}
              fullWidth
            />
          </Grid>
        </Grid>
      </div>
    ));
  };

  let uploadDocuments = [];
  let textDocuments = [];
  let refDocuments = [];
  let expiryDoc = [];
  const classes = useStyles();
  const handleclosemodal = (e, r) => {
    if (r === "backdropClick") {
      return;
    } else {
      handleClose();
    }
  };

  const MAX_SIZE = 10485762; //10mb;
  const handleSsnBlur = () => {};
  return (
    <Modal
      className="modal-container"
      open={open}
      onClose={(event, reason) => handleclosemodal(event, reason)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      backdrop="static"
    >
      <div
        className={`modal-content ${classes.modalResponsive}`}
        style={
          documents[0].data_type == "REFERENCE"
            ? { width: "80%" }
            : { width: "40%" }
        }
      >
        {Credential}
        {loading && <Loader />}
        <div className="modal-icon"></div>
        <div className="modal-heading">{heading} Credential</div>
        <Grid container>
          {documents.map((credential, index) => {
            {
              credential.data_type == "UPLOAD" &&
                uploadDocuments.push(
                  <div style={{ marginLeft: "0px" }}>
                    {" "}
                    {!credentialUpdated[index].upload_data ? (
                      <div className={classes.uploadDocumentContainer}>
                        <div>
                          <Typography
                            align="center"
                            style={{ paddingBottom: "7px" }}
                          >
                            {credential.category.split(" ")[0] == "Document"
                              ? "Add"
                              : credential.category.split(" ")[0]}
                          </Typography>
                          <Dropzone
                            onDrop={(acceptedFiles, fileRejections) => {
                              if (acceptedFiles.length > 0) {
                                docchandler(
                                  credential.id,
                                  acceptedFiles,
                                  index,
                                  credential.data_type
                                );
                              } else if (fileRejections.length > 0) {
                                if (fileRejections[0].file.size > MAX_SIZE) {
                                  Toast.showErrorToast(
                                    "Please Upload a file below 10MB"
                                  );
                                } else {
                                  Toast.showErrorToast(
                                    `Only ${uploadTypes(
                                      credential.upload_type
                                    )} type documents can be uploaded.`
                                  );
                                }
                              } else {
                                Toast.showErrorToast("Something went wrong");
                              }
                            }}
                            accept={uploadTypes(credential.upload_type)}
                            maxSize={MAX_SIZE}
                            multiple={false}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section>
                                <div {...getRootProps()}>
                                  <img
                                    id={index}
                                    category={credential.category}
                                    src={UploadDocumentIcon}
                                    alt="image"
                                  />
                                  <input {...getInputProps()} />
                                </div>
                              </section>
                            )}
                          </Dropzone>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <Typography
                          align="center"
                          style={{ paddingBottom: "13px" }}
                        >
                          {credential.category.split(" ")[0] == "Document"
                            ? "File"
                            : credential.category.split(" ")[0]}{" "}
                        </Typography>
                        <div
                          className={classes.removedFileContainer}
                          style={{ marginTop: "-4px" }}
                        >
                          {credentialUpdated[index].file ? (
                            <ImagePreviewUpdate
                              file={credentialUpdated[index].file}
                              src={preview(credentialUpdated[index].file)}
                              id={credentialUpdated[index].id}
                            />
                          ) : (
                            <a
                              href={credential.upload_data}
                              target="_blank"
                              rel="noopener noreferrer"
                              download
                              className="anchor"
                            >
                              <img
                                id={index}
                                height={"66px"}
                                width={"66px"}
                                File
                                category={credential.category}
                                src={
                                  credential.upload_data_thumbnail
                                    ? credential.upload_data_thumbnail
                                    : getPreview(
                                        credential.upload_data
                                          .substring(
                                            credential.upload_data.lastIndexOf(
                                              "."
                                            ) + 1,
                                            credential.upload_data.length
                                          )
                                          .split("?")[0],
                                        credential.upload_data
                                      )
                                }
                                alt="image"
                                style={{ borderRadius: "15%" }}
                              />
                            </a>
                          )}
                          <img
                            className={classes.imageStyle}
                            onClick={(e) =>
                              removeDocument(
                                e,
                                credential.id,
                                index,
                                credential.data_type
                              )
                            }
                            id={index}
                            src={CloseIcon}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                );
              credential.data_type == "TEXT" &&
                textDocuments.push(
                  <div className={classes.textDocumentsContainer}>
                    {credential_data[id].credential_name ===
                    "Social Security Number" ? (
                      <div className={classes.ssnInput}>
                        <Grid item xs={6} md={8}>
                          <SsnInputField
                            value={textData}
                            id="text_data"
                            label={credential_data[id].credential_name}
                            variant="outlined"
                            onChange={(event) => {
                              handltext(
                                event,
                                index,
                                credential.id,
                                credential.data_type
                              );
                            }}
                            fullWidth
                            onBlur={handleSsnBlur("ssn")}
                          ></SsnInputField>
                        </Grid>
                      </div>
                    ) : (
                      <div className={classes.textContainer}>
                        <Grid item xs={6} md={8}>
                          <InputField
                            id="text_data"
                            value={textData}
                            type={
                              credential.keyboard_type === "alphanumeric"
                                ? "text"
                                : "number"
                            }
                            label={credential_data[id].credential_name}
                            variant="outlined"
                            onChange={(event) => {
                              handltext(
                                event,
                                index,
                                credential.id,
                                credential.data_type
                              );
                            }}
                            fullWidth
                          />
                        </Grid>
                      </div>
                    )}
                  </div>
                );
            }

            {
              credential_data[id].expiry_required &&
                index === 0 && //checking expiry only once
                expiryDoc.push(
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Datepicker
                      value={new Date()}
                      inputVariant="outlined"
                      label="Expiration Date*"
                      format="MM/dd/yyyy"
                      value={expiryDate}
                      placeholder="MM/dd/yyyy"
                      onChange={(e) =>
                        handleDateChange(e, docId, index, credential.data_type)
                      }
                      fullWidth
                      minDate={addDays(new Date(), 1)}
                      maxDate={
                        new Date(
                          new Date().setFullYear(new Date().getFullYear() + 100)
                        )
                      }
                      onBlur={() => "blur working"}
                    ></Datepicker>
                  </MuiPickersUtilsProvider>
                );
            }
            {
              credential.data_type == "REFERENCE" &&
                refDocuments.push(getRefCred(credential));
            }
          })}
          <div className={classes.uploadDocuments}>{uploadDocuments}</div>
        </Grid>

        <div className={classes.textdocs}>{textDocuments}</div>
        <div className={classes.textdocs}>
          <div className={classes.expDocumentsContainer}> {expiryDoc}</div>
        </div>

        <div>{refDocuments}</div>
        <Grid container>
          <div style={{ padding: "0px 30px 30px 30px" }}>
            <span style={{ lineHeight: "normal" }}>
              <h3>Note:</h3>
              <span className="p3 text-muted">
                {extensions.length === 1 &&
                (extensions.includes("text") || extensions.includes("numeric"))
                  ? `Please provide ${
                      credential_data[id].credential_name
                    } credentials of nurse.You can provide ${extensions.toString()} data in given field.`
                  : `Please provide ${
                      credential_data[id].credential_name
                    } credentials of nurse.You can upload ${extensions.toString()} type documents. (Max file size: 10MB)`}{" "}
              </span>
            </span>
          </div>
        </Grid>
        <div className="modal-cta" style={{ marginTop: "40px" }}>
          <SecondaryButton
            variant="contained"
            style={{ height: 54, marginRight: 16 }}
            onClick={closehandle}
            wide
          >
            Cancel
          </SecondaryButton>
          {
            <PrimaryButton
              variant="contained"
              color="primary"
              style={{ height: 54 }}
              onClick={pushCredentials}
              wide
            >
              {heading === "Add" ? "Add" : "Save"}
            </PrimaryButton>
          }
        </div>
      </div>
    </Modal>
  );
};

export default UploadCredentials;
