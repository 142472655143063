import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
const useStyles = makeStyles((theme) => ({
  bottom: {
    color: "linear-gradient(to right,#D831B4, #6241E9)",
  },
  circle: {
    strokeLinecap: "round",
  },
  loaderContainer: {
    padding: 10,
    borderRadius: 8,
    width: '100%',
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
const CircularLoader = (className, style) => {
  const classes = useStyles();
  return (
    <div className={classes.loaderContainer}>
      <CircularProgress
        variant="indeterminate"
        disableShrink
        classes={{
          circle: classes.circle,
        }}
        size={40}
        thickness={4}
      />
    </div>
  );
};

export default CircularLoader;
