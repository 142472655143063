import { withStyles } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";

const Datepicker = withStyles({
  root: {
    "& label.Mui-focused": {},
    "& .MuiOutlinedInput-root": {
      // borderRadius: 27 ,
      borderColor: "#EDECF5",
      padding: "3px 3px",
    },
    //minHeight: 80,
    fontSize: 13,
    fontWeight: 600,
    lineHeight: 20 / 13,
    "& .MuiFormHelperText-root.Mui-error" :{
      fontWeight:"400 !important",
      color:"#ff0040"
    },
    "& .MuiFormLabel-root.Mui-error": {
      color:"#ff0040"
  }
  },
})(KeyboardDatePicker); 
export default Datepicker;
