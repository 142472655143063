import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from 'react';

const useStyles = makeStyles({
  CREDENTIAL_PENDING: {
    width: 130,
    height: 36,
    background: "#FFF8E5",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 13,
    fontWeight: 500,
    color: "#945A04",
    textTransform: "capitalize",
  },
  CREDENTIAL_VERIFIED: {
    width: 130,
    height: 36,
    background: "#E5F8F2",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 13,
    fontWeight: 500,
    color: "#00D498",
    textTransform: "capitalize",
  },
  CREDENTIAL_EXPIRED: {
    width: 130,
    height: 36,
    background: "#EDECF5",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 500,
    color: "    #82889C",
    textTransform: "capitalize",
  },
  CREDENTIAL_REJECTED: {
    width: 130,
    height: 36,
    background: " #FFCBC8",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 500,
    color: " #FF0040",
    textTransform: "capitalize",
  },
});
const CredentialStatus = ({ status }) => {
  const classes = useStyles();

  return (
    <div className={classes[status]}>
      {status?status.replace("_", " ").toLowerCase():"N/A"}
    </div>
  );
};

export default CredentialStatus;
