import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";
import { convertToHumanSize } from "../../utils/fileUtils";
import Toast from "./toast";
import { ellipsizeText } from "../../utils/textUtils";
import { PDFDownloadLink, usePDF } from "@react-pdf/renderer";
import CloseIcon from "../assets/icons/close.svg";
import axios from "axios";
import DownloadIcon from "../assets/icons/DownloadIcon.svg";
let zip = require("jszip")();
let archive = zip.folder("credential");
const useStyles = makeStyles({
  root: {
    backgroundColor: "#F5F6FA",
    borderRadius: 14,
    padding: 8,
    display: "flex",
  },
  image: {
    width: 54,
    height: 54,
    objectFit: "cover",
    marginRight: 16,
  },
  metadata: {
    width: "100%",
  },
  closeButton: {
    float: "right",
    cursor: "pointer",
  },
});

const ImagePreview = ({
  file,
  src,
  name,
  size,
  id,
  progress = false,
  onRemove = null,
  style,
  onClick = null,
  link = null,
  enableDownload = false,
  pdfFileComponent = null,
  credentialKey,
  data,
}) => {
  const classes = useStyles();
  const [instance, update] = usePDF({ document: pdfFileComponent });
  useEffect(() => {
    update({ document: pdfFileComponent });
  }, [pdfFileComponent]);
  
  useEffect(() => {
    const reader = new FileReader();
    const preview = document.getElementById("image-" + id);
    if (src) {
      preview.src = src;
      return;
    }

    reader.addEventListener(
      "load",
      function () {
        // convert image file to base64 string
        preview.src = reader.result;
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  }, [file]);
  const downDoc = async (e) => {
    e.stopPropagation();
    if (!pdfFileComponent) {
      const res = await axios({
        url: link,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${size ? name : file?.name}`);
        document.body.appendChild(link);
        link.click();
      });
    } 
    // else if (pdfFileComponent) {
    //   const { exemption, attestation, employee_signature } = data;
    //   if (credentialKey === "ATTESTATION_COMPLIANCE") {
    //     let promises = [];
    //     let anyDocPresent = false;
    //     if (exemption?.fdh_exemption_document) {
    //       anyDocPresent = true;
    //     }
    //     if (anyDocPresent) {
    //       if (credentialKey === "ATTESTATION_COMPLIANCE")
    //         promises.push(getImageData(exemption?.fdh_exemption_document));
    //       // promises.push(getImageData("https://s3.amazonaws.com/apollo-dev-storage/media/user/87dd68d4-ec55-47af-a9b8-a4afdd6d177d/credential/17c5ec81-105d-4382-a68f-3523fb993a75/png-transparent-contract-computer-icons-rental-agreement-others-miscellaneous-angle-text.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAYJ5PNTXVDU2U4GOW%2F20221009%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20221009T120614Z&X-Amz-Expires=600&X-Amz-SignedHeaders=host&X-Amz-Signature=783129be7d22a915f8a90438f3be71cf11684abab50516996088dd2134f5ae1c"));
    //     }

    //     Promise.all(promises)
    //       .then(() => {
    //         return zip.generateAsync({ type: "blob" });
    //       })
    //       .then((content) => {
    //         // setLoading(false);
    //         const url = window.URL.createObjectURL(content);
    //         const link = document.createElement("a");
    //         link.href = url;
    //         link.download = `archive.zip`;
    //         document.body.appendChild(link);
    //         link.click();
    //       })
    //       .catch(async (error) => {
    //         //if we got 403 rejection means we need to recall the credential to get the updated url for
    //         //credential since they are expired
    //         // setLoading(false);
    //         // setForbiddenError(true);
    //         Toast.showErrorToast("UnableToFetch");
    //       });
    //   }
    // }
  };
  const getImageData = async (url) => {
    const requestOptions = {
      method: "GET",
      mode: "cors",
      credentials: "include",
      cache: "no-cache",
    };
    return new Promise((resolve, reject) => {
      fetch(url, requestOptions)
        .then(function (response) {
          if (response.status === 200) {
            return response.blob();
          } else if (response.status === 403) {
            reject(new Error("Something is not right!"));
          }
        })
        .then((blob) => {
          // here the image is a blob
          let name = url.split("?")[0].split("/").pop();
          const file = new File([blob], name);
          archive.file(name, blob);
          resolve(blob);
        })
        .catch((err) => Toast.showErrorToast("Something went wrong"));
    });
  };
  return (
    <div
      className={classes.root}
      style={{ width: "100%", ...style }}
      onClick={
        pdfFileComponent
          ? () => {
              const link = document.createElement("a");
              link.target = "_blank";
              link.href = instance.url;
              link.setAttribute("click", `${"Asdsd"}`);
              document.body.appendChild(link);
              link.click();
            }
          : () => {}
      }
    >
      <img
        src={null}
        alt=""
        className={classes.image}
        id={"image-" + id}
        onClick={() => (onClick ? onClick(link) : "")}
      />
      <div className={classes.metadata}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: progress ? 14 : 0,
          }}
        >
          {!enableDownload ? (
            <Typography variant="button" align="left">
              {ellipsizeText(size ? name : file?.name ?? "", 16)}
            </Typography>
          ) : (
            <Typography variant="button" align="left">
              {ellipsizeText(name, 16)}{" "}
            </Typography>
          )}
          {onRemove && (
            <img
              src={CloseIcon}
              className={classes.closeButton}
              onClick={onRemove}
              alt=""
            />
          )}
        </div>
        <div>
          {progress ? (
            <LinearProgress
              variant="determinate"
              color="primary"
              style={{ borderRadius: 4 }}
              value={80}
            />
          ) : (
            <Typography variant="button" className="text-muted">
              {size && convertToHumanSize(size ? size : file?.size)}
            </Typography>
          )}
        </div>
      </div>
      {enableDownload && (
        <div style={{ cursor: "pointer" }} onClick={(e) => downDoc(e)}>
          {pdfFileComponent ? (
            <PDFDownloadLink
              document={pdfFileComponent}
              fileName={`${name}.pdf`}
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  "Loading..."
                ) : (
                  <img style={{ marginTop: 15 }} src={DownloadIcon} alt="" />
                )
              }
            </PDFDownloadLink>
          ) : (
            <img style={{ marginTop: 15 }} src={DownloadIcon} alt="" />
          )}
        </div>
      )}
    </div>
  );
};

export default ImagePreview;
