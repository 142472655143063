import React from "react";
import { styled } from "@material-ui/core/styles";
import MenuBar from "./components/MenuBar/MenuBar";
import ReconnectingNotification from "./components/ReconnectingNotification/ReconnectingNotification";
import Room from "./components/Room/Room";
import useHeight from "./hooks/useHeight/useHeight";
import useRoomState from "./hooks/useRoomState/useRoomState";
import useVideoContext from "./hooks/useVideoContext/useVideoContext";
import useParticipants from "./hooks/useParticipants/useParticipants";

const Container = styled("div")({
  display: "grid",
  gridTemplateRows: "1fr auto",
});

const Main = styled("main")(() => ({
  overflow: "hidden",
  backgroundColor: "#040C35",
}));

export default function App(props: any) {
  const roomState = useRoomState();
  const { room } = useVideoContext();
  const localParticipant = room?.localParticipant;
  const [sessionEnded, setSessionEnded] = React.useState(false);
  const participants = useParticipants();
  const [mediaError, setMediaError] = React.useState<Error>();
  const {
    connect: videoConnect,
    localTracks,
    getLocalAudioTrack,
    getLocalVideoTrack,
    getAudioAndVideoTracks,
    isAcquiringLocalTracks,
    isConnecting,
  } = useVideoContext();
  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight();
  React.useEffect(() => handleJoin(), []);
  const handleJoin = () => {
    getAudioAndVideoTracks().catch((error) => {
      setMediaError(error);
    });
    videoConnect(props.token);
  };
  let timer;
  const callPickedorNot=(participants)=>{
    timer= setTimeout(function () {
      if(participants.length===0){
        room?.disconnect();
        window.top.close();
      }
      
    }, 75000);
   
  }

  React.useEffect(() => {
    if (room?.state === "disconnected") {
      setSessionEnded(true);
    }
    if(participants.length===0){
      callPickedorNot(participants);
    }
    if (participants.length > 0 && room === null) {
      setSessionEnded(true);

    }
    if(participants.length>0){
      clearTimeout(timer);

    }
    return ()=>clearTimeout(timer);

   
   
  }, [participants, room]);
 

  return (
    <Container style={{ height }}>
      {roomState === "disconnected" ? (
        sessionEnded && (
          <div className="remote-waiting">
            <div>
              <span className="session-begin">Your session has Ended</span>
            </div>
          </div>
        )
      ) : (
        <Main>
          <ReconnectingNotification />
          <Room /> 
          <MenuBar />
        </Main>
      )}
    </Container>
  );
}
