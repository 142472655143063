import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "../assets/icons/close.svg";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#F5F6FA",
    borderRadius: 14,
    display: "flex",
  },
  image: {
    width: 66,
    height: 66,
    objectFit: "cover",
    borderRadius: "15%",
  },
  metadata: {
    width: "100%",
  },
  closeButton: {
    float: "right",
    cursor: "pointer",
  },
});

const ImagePreviewUpdate = ({
  file,
  src,
  id,
  progress = false,
  onRemove = null,
  style,
  onClick = null,
  link = null,
}) => {
  const classes = useStyles();

  useEffect(() => {
    const reader = new FileReader();
    const preview = document.getElementById("image-" + id);
    if (src) {
      preview.src = src;
      return;
    }

    reader.addEventListener(
      "load",
      function () {
        preview.src = reader.result;
      },
      false
    );
    if (file) {
      reader.readAsDataURL(file);
    }
  }, [file]);
  return (
    <div className={classes.root} style={{ ...style }}>
      <img
        src={null}
        className={classes.image}
        id={"image-" + id}
        onClick={() => (onClick ? onClick(link) : "")}
      />
      <div className={classes.metadata}>
        <div
          style={{
            display: "flex",
            //minWidth: 150,
            justifyContent: "space-between",
            paddingBottom: progress ? 14 : 0,
          }}
        >
          {onRemove && (
            <img
              src={CloseIcon}
              className={classes.closeButton}
              onClick={onRemove}
            />
          )}
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default ImagePreviewUpdate;
