import React from "react";
import Modal from "@material-ui/core/Modal";
import {
  PrimaryButton,
  SecondaryButton,
  Loader,
  Datepicker,
  ImagePreview,
} from "../../components";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { addDays } from "date-fns";
import Dropzone from "react-dropzone";
import UploadDocumentIcon from "../../assets/icons/UploadModalIcon@3x.svg";
import ThumbnailPlaceHolder from "../../assets/icons/thumbnail-placeholder.svg";
import { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CloseIcon from "../../assets/icons/cross.svg";
import deleteIcon from "../../assets/icons/delete.svg";
import { Toast } from "../../components";
import ImagePreviewUpdate from "../ImagePreviewCred";
import moment from "moment";
import PDFUploaded from "../../assets/images/pdf-uploaded.svg";
import DOCUploaded from "../../assets/images/docUpload.svg";
import TwoFactorUpload from "../../assets/icons/twoFactorUpload.svg";
import { Axios } from "../../../api/apiConsts";

const useStyles = makeStyles({
  uploadDocumentContainer: {
    padding: "0px 0px 0px 20px",
    display: "flex",
    // justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    marginLeft: "-26px",
  },
  removedFileContainer: {
    display: "flex",
    marginLeft: "15px",
    marginTop: "-7px",
    justifyContent: "center",
    // height: 150,
  },
  imageStyle: {
    alignSelf: "flex-start",
    marginLeft: "-10px",
    marginTop: "-10px",
  },
  reftitle: {
    marginBottom: 10,
    display: "flex",
  },
  refdelete: {
    height: 25,
    marginLeft: "10px",
    marginTop: -3,
  },
  textDocumentsContainer: {
    width: "80%",
  },
  expDocumentsContainer: {},
  ssnInput: {
    marginRight: "0px",
  },
  textContainer: {
    marginRight: "0",
  },
  uploadDocuments: {
    display: "flex",
    paddingLeft: "15px",
    flexWrap: "wrap",
  },
  textdocs: {
    width:'100%',
    display: "flex",
    marginTop: "15px",
    marginLeft:100
  },
  modalResponsive: {
    width: "600px !important",
    ["@media (max-width:1150px)"]: {
      //   width: "80% !important",
    },
  },
  center: {
    //marginLeft: "190px",
    //width: 0,
    display: "flex",
    justifyContent: "center",
  },
  modalHeading: {
    // marginLeft: "148px",
    fontSize: "20px !important",
    fontWeight: "500 !important",
    // textAlign: "inherit !important",
  },
  modalSubText: {
    fontSize: 14,
    marginLeft: "100px",
  },
  dropzoneContainer: {
    border: "1px dashed #82889C",
    borderRadius: "12px",
    backgroundColor: "#FFFFFF",
    height: "150px",
    width: "400px",
   // marginLeft: "230px",
  },
  spanEmp: {
    marginLeft: "102px",
    marginTop: 15,
    marginBottom: 15,
    fontSize: 14,
  },
  displayInputStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  imageUpCss: {
    marginTop: 10,
  },
  scanUploadText: {
    color: "#050D33",
    fontSize: 15,
    marginTop: 10,
  },
  infoText: {
    color: "#82889C",
    fontSize: 11,
    marginTop: 5,
  },
  anchorTag: {
    border: "1px dashed #82889C",
    borderRadius: "12px",
    backgroundColor: "#FFFFFF",
    height: "150px",
    width: "400px",
    marginLeft: "216px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 10,
  },
  imagePreview: {
    width: "30%",
  },
});

const UploadCredentials = ({
  credential_data,
  id,
  handleClose,
  employeeID,
  heading,
  getCredentials,
  open,
  extensions,
}) => {
  const [signDoc, setSignDoc] = useState(
    credential_data[id]?.credential_data[0]?.upload_data_2
      ? credential_data[id]?.credential_data[0]?.upload_data_2
      : null
  );
  const [docStatus, setDocStatus] = useState(null);
  const [documents, setDocuments] = useState(
    credential_data[id].credential_data
  );
  const [loading, setLoading] = useState(false);
  const [credId, setCredId] = useState(null);
  const [updatedExpiryCred, setupdatedExpiryCred] = useState([]);
  const [expiryDate, setexpiryDate] = useState(credential_data[id]?.expiry);
  const [docId, setdocId] = useState(credential_data[id]?.id);
  let doc = [];
  documents.forEach((cred) => {
    {
      doc.push({
        id: cred.id,
        file: cred.file,
        data_type: cred.data_type,
        upload_data: cred.upload_data_2,
      });
    }
  });

  const closehandle = () => {
    setDocuments(credential_data);
    handleClose();
  };

  const credentialsHandler = async () => {
    return new Promise(async (resolve, reject) => {
      const packet = {
        status: docStatus,
        feedback: "",
      };
      try {
        const response = await Axios.patch(
          `/nurse/credentials/${docId}/action`,
          packet
        );

        if (response.data.success) {
          setLoading(false);
          if (response.datastatusCode === 200) {
            resolve();
          }
        }
      } catch (error) {
        setLoading(false);
        reject();
      }
    });
  };

  const updateDocument = () => {
    setLoading(true);
    let promiseArray = [];
    if (credId) {
      promiseArray.push(pushSignedDoc());
    }
    if (updatedExpiryCred.length > 0) {
      promiseArray.push(pushExpiry(updatedExpiryCred));
    }
    if (docStatus) {
      promiseArray.push(credentialsHandler());
    }
    if (promiseArray.length > 0) {
      Promise.all([promiseArray])
        .then((res) => {
          setLoading(false);
          handleClose();
          Toast.showInfoToast("Credential Updated successfully");
        })
        .catch((err) => {
          setLoading(false);
          handleClose();
          Toast.showErrorToast("Error in Updating credential");
          getCredentials();
        });
    } else {
      handleClose();
    }
  };

  const pushSignedDoc = async () => {
    return new Promise(async (resolve, reject) => {
      const packet = new FormData();
      packet.append("upload_data_2", signDoc);
      try {
        const response = await Axios.patch(
          `nurse/credentials/${credId}`,
          packet
        );

        if (response.data.success) {
          setLoading(false);
          if (response.data.statusCode === 200) {
            resolve();
            getCredentials();
          }
        }
      } catch (error) {
        reject();
      }
    });
  };

  let initialUploadType = {
    jpg: ".jpg, .jpeg",
    png: ".png, .PNG",
    pdf: ".pdf, .PDF",
    doc: ".doc, .docx",
  };

  const uploadTypes = (arr) => {
    let finalStr = [];
    if (arr) {
      arr.map((item) => {
        finalStr.push(initialUploadType[item]);
      });
    }
    return finalStr.join();
  };

  const preview = (image, link) => {
    let icon = "";
    if (
      image.type === "image/jpg" ||
      image.type === "image/png" ||
      image.type === "image/jpeg" ||
      image.type === "image/PNG" ||
      image === "jpg" ||
      image === "png" ||
      image === "jpeg" ||
      image === "PNG"
    ) {
      icon = link;
    } else if (image.type === "application/pdf" || image === "pdf") {
      icon = PDFUploaded;
    } else if (
      image.type === "application/msword" ||
      image.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      icon = DOCUploaded;
    }
    return icon;
  };

  let uploadDocuments = [];
  let expiryDoc = [];
  const classes = useStyles();
  const handleclosemodal = (e, r) => {
    if (r === "backdropClick") {
      return;
    } else {
      handleClose();
    }
  };
  const get_url_extension = (url) => {
    return url.split(/[#?]/)[0].split(".").pop().trim();
  };

  const handleDateChange = (date, docID, index, data_type, expiry) => {
    setexpiryDate(date);
    setexpiryDate(date);
    let arr = [...updatedExpiryCred];
    arr[index] = {
      ...updatedExpiryCred[index],
      docId: docID,
      data_type: "Expiry",
      expiry: moment(date).format("YYYY-MM-DD"),
    };
    setupdatedExpiryCred(arr);
  };

  const pushExpiry = (updatedExpiryCred) => {
    return new Promise((resolve, reject) => {
      const packet = {
        expiry: moment(updatedExpiryCred[0].expiry).format("YYYY-MM-DD"),
      };
      Axios.patch(
        `nurse/${employeeID}/credential/expiry/${updatedExpiryCred[0].docId}`,
        packet
      )
        .then((response) => {
          if (response.data.statusCode === 200) {
            resolve();
          }
        })
        .catch((error) => {
          reject();
        });
    });
  };

  const MAX_SIZE = 10485762; //10mb;
  return (
    <Modal
      className="modal-container"
      open={open}
      onClose={(event, reason) => handleclosemodal(event, reason)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      backdrop="static"
    >
      <div
        className={`modal-content ${classes.modalResponsive}`}
        style={{ width: "40%" }}
      >
        {Credential}

        {loading && <Loader />}
        <div className="modal-icon"></div>
        <div className={`modal-heading ${classes.modalHeading}`}>
          {credential_data[id]?.credential_name}
        </div>
        <div className={classes.modalSubText}>
          Download the below employee document and upload
        </div>

        <ImagePreview
          className={classes.imagePreview}
          link={credential_data[id]?.credential_data[0]?.upload_data}
          src={preview(
            get_url_extension(
              credential_data[id]?.credential_data[0]?.upload_data
            ),
            credential_data[id]?.credential_data[0]?.upload_data_thumbnail
          )}
          name={credential_data[id]?.credential_data[0]?.filename.split("?")[0]}
          size={10000}
          style={{ width: "400px", marginLeft: 100, marginTop: 10 }}
          id={"asdasdsdc"}
          enableDownload={true}
        />

        <p className={classes.spanEmp}>Employer</p>

        <Grid container className={classes.center}>
          {documents.map((credential, index) => {
            {
              credential.data_type == "UPLOAD" &&
                uploadDocuments.push(
                  <div>
                    {" "}
                    {signDoc === null || signDoc === "" ? (
                      <div className={classes.uploadDocumentContainer}>
                        <div>
                          <Typography
                            align="center"
                            style={{ paddingBottom: "7px" }}
                          >
                            {credential.category?.split(" ")[0] == "Document"
                              ? ""
                              : credential.category?.split(" ")[0]}
                          </Typography>
                          <Dropzone
                            onDrop={(acceptedFiles, fileRejections) => {
                              if (acceptedFiles.length > 0) {
                                setSignDoc(acceptedFiles[0]);
                                setCredId(credential.id);
                                setDocStatus("APPROVED");
                              } else if (fileRejections.length > 0) {
                                if (fileRejections[0].file.size > MAX_SIZE) {
                                  Toast.showErrorToast(
                                    "Max file size exceeded"
                                  );
                                } else {
                                  Toast.showErrorToast(`Wrong type document`);
                                }
                              }
                            }}
                            accept={uploadTypes(credential.upload_type)}
                            maxSize={MAX_SIZE}
                            multiple={false}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section>
                                <div
                                  {...getRootProps()}
                                  className={classes.dropzoneContainer}
                                >
                                  {/* <img
                                    id={index}
                                    category={credential.category}
                                    src={UploadDocumentIcon}
                                    alt="image"
                                  /> */}
                                  <div className={classes.displayInputStyle}>
                                    <img
                                      className={classes.imageUpCss}
                                      src={TwoFactorUpload}
                                    ></img>
                                    <div className={classes.scanUploadText}>
                                      Scan & Upload
                                    </div>
                                    <div className={classes.infoText}>
                                      Supports {extensions.toString()} type |
                                      Max file size 10 MB
                                    </div>
                                  </div>
                                  <input {...getInputProps()} />
                                </div>
                              </section>
                            )}
                          </Dropzone>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className={classes.anchorTag}>
                          <div
                            className={classes.removedFileContainer}
                            style={{ marginTop: "-4px" }}
                          >
                            {typeof signDoc === "object" ? (
                              <ImagePreviewUpdate
                                file={signDoc}
                                src={preview(signDoc)}
                                id={"as"}
                              />
                            ) : (
                              <a
                                href={credential.upload_data_2}
                                target="_blank"
                                rel="noopener noreferrer"
                                download
                                className="anchor"
                                //className={classes.anchorTag}
                              >
                                <img
                                  id={index}
                                  height={"66px"}
                                  width={"66px"}
                                  File
                                  category={credential.category}
                                  src={
                                    credential.upload_data_2_thumbnail
                                      ? credential.upload_data_thumbnail
                                      : ThumbnailPlaceHolder
                                  }
                                  alt="image"
                                  style={{ borderRadius: "15%" }}
                                />
                              </a>
                            )}
                            <img
                              className={classes.imageStyle}
                              onClick={(e) => {
                                setSignDoc("");
                                setDocStatus("PENDING");
                                setCredId(credential.id);
                              }}
                              id={index}
                              src={CloseIcon}
                            />
                          </div>
                          <Typography
                            align="center"
                            style={{ paddingBottom: "13px" }}
                          >
                            {typeof signDoc !== "object"
                              ? credential_data[
                                  id
                                ]?.credential_data[0]?.filename_2.split("?")[0]
                              : signDoc?.path}
                          </Typography>
                        </div>
                      </>
                    )}
                  </div>
                );
            }
            {
              credential_data[id]?.expiry_required &&
                index === 0 && //checking expiry only once
                expiryDoc.push(
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Datepicker
                      value={new Date()}
                      inputVariant="outlined"
                      label="Expiration Date*"
                      format="MM/dd/yyyy"
                      value={expiryDate}
                      placeholder="MM/dd/yyyy"
                      onChange={(e) =>
                        handleDateChange(e, docId, index, credential.data_type)
                      }
                      fullWidth
                      minDate={addDays(new Date(), 1)}
                      maxDate={
                        new Date(
                          new Date().setFullYear(new Date().getFullYear() + 100)
                        )
                      }
                      onBlur={() => "blur working"}
                    ></Datepicker>
                  </MuiPickersUtilsProvider>
                );
            }
          })}
          <div className={classes.uploadDocuments}>{uploadDocuments}</div>
          <div className={classes.textdocs}>
            <div className={classes.expDocumentsContainer}> {expiryDoc}</div>
          </div>
        </Grid>
        <div className="modal-cta" style={{ marginTop: "40px" }}>
          <SecondaryButton
            variant="contained"
            style={{ height: 54, marginRight: 16 }}
            onClick={closehandle}
          >
            Cancel
          </SecondaryButton>
          {
            <PrimaryButton
              variant="contained"
              color="primary"
              style={{ height: 54 }}
              onClick={updateDocument}
              wide
            >
              {heading === "Add" ? "Add" : "Save"}
            </PrimaryButton>
          }
        </div>
      </div>
    </Modal>
  );
};

export default UploadCredentials;
