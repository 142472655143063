import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';
import EndCall from "../../../../../assets/icons/endcall.svg";
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brand,
      color: 'white',
      '&:hover': {
        background: '#600101',
      },
    },
  })
);

export default function EndCallButton(props: { className?: string }) {
  const classes = useStyles();
  const { room } = useVideoContext();

  const closeWin=()=> { 
    window.top.close();
}

  return (
    // <Button onClick={() => room?.disconnect()} className={clsx(classes.button, props.className)} data-cy-disconnect>
    //   Disconnect
    // </Button>
    <div className="abs-cont">
    <img
      src={EndCall}
      onClick={() =>{
         room?.disconnect()
         setTimeout(function () {
          closeWin();
        }, 3000);
        }}
      className="call-img"
      data-cy-disconnect
    ></img>
  </div>
  );
}
