import React from "react";
import Button from "@material-ui/core/Button";

const EditDeleteUploadButton = ({ onClick, id,lable }) => {
  return (
    <Button
      id={id}
      onClick={onClick}
      className="approve-btn"
      variant="contained"
      style={{ boxShadow: "none", marginLeft: "3px" }}
    >
      {lable}
    </Button>
  );
};

export default EditDeleteUploadButton;
