import React, { useState, useCallback, useEffect } from "react";
import InputBase from "@material-ui/core/InputBase";

import SearchOutlinedIcon from "./assets/search.svg";
import SearchIcon from "./assets/search-line.svg";
import CrossIcon from "./assets/cross-icon.svg";
import { debounce } from "lodash";

const SearchInput = ({
  value,
  onChange,
  onClose,
  style,
  className,
  handleSearchOnTabChange,
  activeTab,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState(value);
  const handler = useCallback(debounce(onChange, 500), []);

  const handleChange = (e) => {
    setSearchValue(e.target.value);
    e.persist();
    handler(e);
  };

  const onCrossIconClick = () => {
    setIsOpen(false);
    setSearchValue("");
    if (onClose) {
      onClose();
    }
  };

  const closeBox = () => {
    setIsOpen(false);
    setSearchValue("");
  };

  useEffect(() => {
    if (handleSearchOnTabChange) handleSearchOnTabChange(closeBox);
  }, [activeTab]);

  return (
    <div style={style} className={className}>
      {isOpen ? (
        <div
          className="row-center"
          style={{
            // borderRadius: 26,
            border: "solid 1px #EDECF5",
            height: 42,
            // marginBottom: "5px",
          }}
        >
          <img
            src={SearchIcon}
            style={{ marginLeft: 10, marginRight: 10 }}
            alt="search"
          />
          <InputBase
            value={searchValue}
            onChange={(e) => handleChange(e)}
            style={{
              fontSize: 13,
              lineHeight: 20 / 13,
              fontWeight: 600,
            }}
          />
          <div onClick={onCrossIconClick} className="cursor-pointer">
            <img
              src={CrossIcon}
              style={{ marginBottom: -5, marginRight: 10 }}
              alt="search"
            />
          </div>
        </div>
      ) : (
        <div onClick={() => setIsOpen(true)} className="cursor-pointer">
          <img src={SearchOutlinedIcon} alt="" />
        </div>
      )}
    </div>
  );
};

export default SearchInput;
