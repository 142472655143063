import React, { useState, useEffect, useRef } from "react";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Formik } from "formik";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import { v4 as uuidv4 } from "uuid";
import PDFUploaded from "../../assets/images/pdf-uploaded.svg";
import DOCUploaded from "../../assets/images/docUpload.svg";

import {
  PrimaryButton,
  TransparentButton,
  Toast,
  Loader,
  ImagePreview,
  SsnInputField,
  InputField,
  Radio,
} from "..";
//import { API, ENDPOINTS } from "../../../api/apiService";
import * as Yup from "yup";
//import { apiErrorHandler } from "../../../utils/apiUtil";
import { Axios } from "../../../api/apiConsts";

const useStyles = makeStyles({
  opa: {
    opacity: 0.3,
    pointerEvents: "none",
  },
  root: {
    padding: "35px 40px 20px 20px",
  },
  contentRoot: {
    maxHeight: 630,
    minWidth: 720,
    maxWidth: 800,
  },
  content: {},
  paper: {
    marginBottom: 15,
    //  justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  gridItem: {
    textAlign: "left",
  },
  width: {
    minWidth: 240,
    textAlign: "left",
  },
  subtitle: {
    marginBottom: 24,
  },
  subtitleSetting: {
    marginBottom: 8,
  },
  note: {
    marginBottom: 16,
    marginTop: 8,
    color: "#888fa0",
  },
  description: {
    width: 364,
    textAlign: "center",
    marginBottom: 64,
  },
  documentPlaceholder: {
    backgroundColor: "#F5F6FA",
    padding: 15,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    borderRadius: 8,
  },
  downloadLinkContainer: {
    alignSelf: "center",
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: 8,
    padding: "12px 15px 12px 15px",
    cursor: "pointer",
  },
  footer: {
    backgroundColor: "#F5F6FA",
    width: "100%",
    padding: "23px 0px",
    display: "flex",
    justifyContent: "center",
  },
  header: {
    paddingBottom: 10,
  },
  opa: {
    opacity: 0.3,
    pointerEvents: "none",
  },
  uploadBtnDropzone: {
    border: "2px solid #FF0083",
    background: "#FFFFFF",
    color: "#FF0083",
    height: 45,
    width: 200,
  },
  btnDisable: {
    border: "2px solid #82889C !important",
    color: "#82889C",
  },
  dnd: {
    marginLeft: 200,
    marginBottom: 8,
  },
  radioImg: {
    position: "absolute",
    left: 0,
    pointerEvents: "all !important",
  },
});

const EntityDocsComponent = (props) => {
  const {
    setAllDocsFilled,
    fid,
    submitClicked,
    ein,
    dataHandlerAdd,
    fromEdit = false,
    allDocsFilled,
    AddFacilityHandler,
    setFacilityApiAdd,
  } = props;
  const classes = useStyles();
  const [KYCDetail, setKYCDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const { facilityInfo } = props;
  const [einImage, setEINImage] = useState([]);
  const [ss4Image, setSs4Image] = useState([]);
  const [aoi, setAoi] = useState([]);
  const [backId, setBackId] = useState([]);
  const [facilityData, setFacilityData] = useState(null);
  const [entityName, setEntityName] = useState("");
  const [errEtName, setErrEtName] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState("EIN");
  const [kycApiCalled, setKycApiCalled] = useState(false);

  const [doc, setDoc] = useState([]);
  const firstUpdate = useRef(false);
  useEffect(() => {
    if (firstUpdate.current) {
      if (submitClicked) {
        checkAllFilled();
      }
    } else {
      firstUpdate.current = true;
    }
  }, [submitClicked]);

  useEffect(() => {
    getKYCDocument();
  }, [fid]);

  useEffect(() => {
    if (firstUpdate.current) {
      if (!fromEdit) {
        if (allDocsFilled) {
          AddFacilityHandler();
        }
      } else {
        firstUpdate.current = true;
      }
    }
  }, [allDocsFilled]);
  useEffect(() => {
    if (fid && submitClicked && allDocsFilled) {
      submitForm();
    }
  }, [fid, submitClicked, allDocsFilled]);
  const getKYCDocument = async () => {
    setLoading(true);
    try {
      const resp = await Axios.get(`facility/${props.fid}/bank/kyc`);
      if (resp.data.success) {
        setFacilityData(resp.data.data);
        const { ein_doc } = resp.data.data || {};
        if (ein_doc?.data !== null) setSelectedDoc("EIN");
        else setSelectedDoc("SS4");
      }
    } catch (e) {
      if (e.status !== 500) {
        Toast.showErrorToast(e.data?.error?.message[0]);
      }
      return false;
    } finally {
      setLoading(false);
    }
  };
  const handleRemoveFrontImage = (index) => {
    const newImages = [...aoi];
    newImages.splice(index, 1);
    setAoi(newImages);
  };

  const handleRemoveEINImage = (index) => {
    const newImages = [...einImage];
    newImages.splice(index, 1);
    setEINImage(newImages);
  };

  const handleRemoveSsnImage = (index) => {
    const newImages = [...ss4Image];
    newImages.splice(index, 1);
    setSs4Image(newImages);
  };
  const handleRemoveUploadedImage = async (doc) => {
    setLoading(true);
    try {
      const resp = await Axios.delete(
        `facility/${props.fid}/bank/kyc?doc_type=${doc}`
      );

      if (resp.data.success) {
        getKYCDocument();
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      Toast.showErrorToast(e.data.error.message[0]);
    }
  };

  const UploadImage = async (payload) => {
    try {
      const resp = await Axios.patch(
        `facility/${props.fid}/bank/kyc`,
        payload,
        {
          "Content-Type": "multipart/form-data",
        }
      );
      if (resp.data.success) {
        setAllDocsFilled(false);
        !fromEdit && dataHandlerAdd();
        return true;
      }
    } catch (e) {
      console.log(e);
      setFacilityApiAdd(false);
      // setAllDocsFilled(false);

      Toast.showErrorToast(e.data.error.message[0]);
      return false;
    }
  };

  const handleUpdation = (values) => {
    submitForm(values);
  };

  const getPreview = (type, link) => {
    let icon = "";
    if (type === "jpg" || type === "png" || type === "jpeg") {
      icon = link;
    } else if (type === "pdf") {
      icon = PDFUploaded;
    } else if (type === "doc" || type === "docx") {
      icon = DOCUploaded;
    }
    return icon;
  };

  const preview = (image) => {
    let icon = "";
    if (
      image.type === "image/jpg" ||
      image.type === "image/png" ||
      image.type === "image/jpeg"
    ) {
      icon = null;
    } else if (image.type === "application/pdf") {
      icon = PDFUploaded;
    } else if (
      image.type === "application/msword" ||
      image.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      icon = DOCUploaded;
    }
    return icon;
  };

  const checkAllFilled = () => {
    if (
      einImage.length === 0 &&
      selectedDoc === "EIN" &&
      (facilityData
        ? facilityData?.ein_doc?.data === null
        : einImage.length === 0)
    ) {
      Toast.showErrorToast("EIN Document required");
      setLoading(false);
      setAllDocsFilled(false);
      return;
    }
    if (
      (facilityData
        ? facilityData?.ss4_doc?.data === null
        : ss4Image.length === 0) &&
      ss4Image.length === 0 &&
      selectedDoc === "SS4"
    ) {
      Toast.showErrorToast("SS4 Document required");
      setLoading(false);
      setAllDocsFilled(false);

      return;
    }
    if (
      aoi.length === 0 &&
      (facilityData ? facilityData?.aoi?.data === null : aoi.length === 0)
    ) {
      Toast.showErrorToast("Please upload AOI doc");
      setLoading(false);
      setAllDocsFilled(false);

      return;
    }
    setAllDocsFilled(true);
  };
  const submitForm = async (values) => {
    // kycBlank(ein, ss4, aoi, selectedDoc, facilityData)
    setLoading(true);

    // check if EIN document availble or not
    //if only admin than only we have to check EIN since EIN is of Facility not applicable for sub-admin user
    if (
      einImage.length === 0 &&
      selectedDoc === "EIN" &&
      facilityData?.ein_doc?.data === null
    ) {
      Toast.showErrorToast("EIN Document required");
      setLoading(false);
      return;
    }
    if (
      facilityData?.ss4_doc?.data === null &&
      ss4Image.length === 0 &&
      selectedDoc === "SS4"
    ) {
      Toast.showErrorToast("SS4 Document required");
      setLoading(false);
      return;
    }
    //check ids available or not

    if (aoi.length === 0 && !facilityData?.aoi?.data === null) {
      Toast.showErrorToast("Please upload AOI doc");
      setLoading(false);
      return;
    }

    let uploadDoc = true;

    //upload government document
    const payload1 = new FormData();
    if (aoi.length !== 0) {
      payload1.set("aoi", aoi[0]);
    }
    // if (backId.length !== 0) {
    //   payload1.set("govt_id_back", backId[0]);
    // }
    if (einImage.length > 0 && selectedDoc === "EIN") {
      payload1.set("ein_doc", einImage[0]);
    }
    if (ss4Image.length > 0 && selectedDoc === "SS4") {
      payload1.set("ss4_doc", ss4Image[0]);
    }
    if (ein) {
      payload1.set("tin", ein);
    }
    if (values?.ssn?.length > 0) {
      //    payload1.set("ssn", values.ssn);
    }
    uploadDoc = await UploadImage(payload1);
    if (uploadDoc) {
      setKycApiCalled(false);
    } else {
      setKycApiCalled(false);
    }
    //upload ssn to facility profile
    // if (uploadDoc) {
    //   const payload3 = new FormData();
    //   payload3.set("tin", values.tin);
    //   ssnUpdated = await UpdateFacilitySSN(payload3);
    // }
    setLoading(false);
    // props.handleClose();
    // props.getKYCDocument();
    // props.getLegalName();
  };

  // New functions

  const isRadioSelected = (str) => {
    return selectedDoc === str;
  };
  const MAX_SIZE = 10485762;

  return (
    <div className={classes.content}>
      <Formik
        enableReinitialize
        initialValues={{
          tin: facilityData?.tin?.data ?? "",
          ssn: facilityData?.ssn?.data ?? "",
        }}
        validationSchema={Yup.object().shape({
          tin: Yup.string()
            .required("TIN is required")
            .test("is-valid-tin", "Please enter correct tin number", (val) => {
              let patt = new RegExp("^(\\d{3}-\\d{2}-\\d{4})");
              let res = patt.test(val);
              if (!res) {
                return false;
              }
              return true;
            }),
          ssn: Yup.string()
            .required("SSN is required")
            .test("is-valid-ssn", "Please enter correct ssn number", (val) => {
              var patt = new RegExp(
                "^(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}"
              );
              var res = patt.test(val);
              if (!res) {
                return false;
              }
              return true;
            }),
        })}
        onSubmit={(values) => {
          handleUpdation(values);
        }}
      >
        {({ handleSubmit }) => (
          <>
            <form onSubmit={handleSubmit}>
              {loading && <Loader />}
              <Paper className={classes.paper}>
                <br />
                <span className="p2" style={{ fontWeight: 400 }}>
                  Entity Documents
                </span>
                <br />
                <br />

                <Grid container spacing={2}>
                  <Grid item xs={6} className={classes.gridItem}>
                    {true && (
                      <>
                        <Radio
                          isOn={isRadioSelected("EIN")}
                          onToggle={() => setSelectedDoc("EIN")}
                          label="EIN Number Document"
                          style={{
                            justifyContent: "flex-start",
                          }}
                        ></Radio>

                        <div className={selectedDoc === "SS4" && classes.opa}>
                          {einImage?.length === 0 &&
                          (facilityData
                            ? facilityData?.ein_doc?.data === null
                            : einImage?.length === 0) ? (
                            <Dropzone
                              onDrop={(acceptedFiles, fileRejections) => {
                                if (acceptedFiles.length > 0) {
                                  setEINImage([...acceptedFiles]);
                                } else if (fileRejections.length > 0) {
                                  if (fileRejections[0].file.size > MAX_SIZE) {
                                    Toast.showErrorToast(
                                      "Please Upload a file below 10MB"
                                    );
                                  } else {
                                    Toast.showErrorToast(
                                      "Please upload document in (.jpeg, .png, .jpg, .pdf, .doc) one of these format."
                                    );
                                  }
                                }
                              }}
                              accept=".jpeg, .png, .jpg, .pdf, .doc, .docx"
                              multiple={false}
                              maxSize={MAX_SIZE}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div
                                  className={classes.documentPlaceholder}
                                  {...getRootProps()}
                                >
                                  <Typography
                                    variant="p2Dark"
                                    align="center"
                                    style={{ margin: 20, marginTop: 0 }}
                                  >
                                    Drag drop or
                                  </Typography>

                                  <PrimaryButton
                                    wide
                                    className={`${classes.uploadBtnDropzone} ${
                                      selectedDoc === "SS4" &&
                                      classes.btnDisable
                                    }`}
                                  >
                                    Upload
                                    <input {...getInputProps()} />
                                  </PrimaryButton>
                                  <span
                                    style={{
                                      fontSize: 11,
                                      marginTop: 5,
                                      marginLeft: 5,
                                    }}
                                  >
                                    (supported file type docx,pdf,png,jpg)
                                  </span>
                                  <span
                                    style={{
                                      fontSize: 11,
                                      marginTop: 5,
                                      marginLeft: 5,
                                    }}
                                  >
                                    (max file size 10MB)
                                  </span>
                                </div>
                              )}
                            </Dropzone>
                          ) : (
                            <div
                              className="row-center"
                              style={{ marginTop: 10, marginRight: 20 }}
                            >
                              <Grid container spacing={2}>
                                {facilityData?.ein_doc?.data && (
                                  <Grid item>
                                    <ImagePreview
                                      src={getPreview(
                                        facilityData?.ein_doc?.filename
                                          .split(".")
                                          .reverse()[0],
                                        facilityData?.ein_doc?.data
                                      )}
                                      name={facilityData?.ein_doc?.filename}
                                      size={facilityData?.ein_doc?.filesize}
                                      id={uuidv4()}
                                      onRemove={() =>
                                        handleRemoveUploadedImage("ein_doc")
                                      }
                                    />
                                  </Grid>
                                )}
                                {einImage.map((image, index) => (
                                  <Grid item key={index}>
                                    <ImagePreview
                                      file={image}
                                      src={preview(image)}
                                      id={uuidv4()}
                                      onRemove={() =>
                                        handleRemoveEINImage(index)
                                      }
                                    />
                                  </Grid>
                                ))}
                              </Grid>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={6} className={classes.gridItem}>
                    {true && (
                      <>
                        <Radio
                          isOn={isRadioSelected("SS4")}
                          onToggle={() => setSelectedDoc("SS4")}
                          label="SS4 Document"
                          style={{
                            justifyContent: "flex-start",
                          }}
                        ></Radio>
                        <div className={selectedDoc === "EIN" && classes.opa}>
                          {ss4Image.length === 0 &&
                          (facilityData
                            ? facilityData?.ss4_doc?.data === null
                            : ss4Image.length === 0) ? (
                            <Dropzone
                              onDrop={(acceptedFiles, fileRejections) => {
                                if (acceptedFiles.length > 0) {
                                  setSs4Image([...acceptedFiles]);
                                } else if (fileRejections.length > 0) {
                                  if (fileRejections[0].file.size > MAX_SIZE) {
                                    Toast.showErrorToast(
                                      "Please Upload a file below 10MB"
                                    );
                                  } else {
                                    Toast.showErrorToast(
                                      "Please upload document in (.jpeg, .png, .jpg, .pdf, .doc) one of these format."
                                    );
                                  }
                                }
                              }}
                              accept=".jpeg, .png, .jpg, .pdf, .doc, .docx"
                              multiple={false}
                              maxSize={MAX_SIZE}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div
                                  className={classes.documentPlaceholder}
                                  {...getRootProps()}
                                >
                                  <Typography
                                    variant="p2Dark"
                                    align="center"
                                    style={{ margin: 20, marginTop: 0 }}
                                  >
                                    Drag drop or
                                  </Typography>

                                  <PrimaryButton
                                    wide
                                    className={`${classes.uploadBtnDropzone} ${
                                      selectedDoc === "EIN" &&
                                      classes.btnDisable
                                    }`}
                                  >
                                    Upload
                                    <input {...getInputProps()} />
                                  </PrimaryButton>
                                  <span
                                    style={{
                                      fontSize: 11,
                                      marginTop: 5,
                                      marginLeft: 5,
                                    }}
                                  >
                                    (supported file type docx,pdf,png,jpg)
                                  </span>
                                  <span
                                    style={{
                                      fontSize: 11,
                                      marginTop: 5,
                                      marginLeft: 5,
                                    }}
                                  >
                                    (max file size 10MB)
                                  </span>
                                </div>
                              )}
                            </Dropzone>
                          ) : (
                            <div
                              className="row-center"
                              style={{ marginTop: 10, marginRight: 20 }}
                            >
                              <Grid container spacing={2}>
                                {facilityData?.ss4_doc?.data && (
                                  <Grid item>
                                    <ImagePreview
                                      src={getPreview(
                                        facilityData?.ss4_doc?.filename
                                          .split(".")
                                          .reverse()[0],
                                        facilityData?.ss4_doc?.data
                                      )}
                                      name={facilityData?.ss4_doc?.filename}
                                      size={facilityData?.ss4_doc?.filesize}
                                      id={uuidv4()}
                                      onRemove={() =>
                                        handleRemoveUploadedImage("ss4_doc")
                                      }
                                    />
                                  </Grid>
                                )}
                                {ss4Image.map((image, index) => (
                                  <Grid item key={index}>
                                    <ImagePreview
                                      file={image}
                                      src={preview(image)}
                                      id={uuidv4()}
                                      onRemove={() =>
                                        handleRemoveSsnImage(index)
                                      }
                                    />
                                  </Grid>
                                ))}
                              </Grid>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={6} className={classes.gridItem}>
                    <span
                      className="p2"
                      style={{
                        fontWeight: 600,
                        fontSize: 13,
                        marginBottom: 8,
                      }}
                    >
                      Articles Of Incorporation{" "}
                    </span>
                    {aoi.length === 0 &&
                    (facilityData
                      ? facilityData?.aoi?.data === null
                      : aoi.length === 0) ? (
                      <Dropzone
                        onDrop={(acceptedFiles, fileRejections) => {
                          if (acceptedFiles.length > 0) {
                            setAoi([...acceptedFiles]);
                          } else if (fileRejections.length > 0) {
                            if (fileRejections[0].file.size > MAX_SIZE) {
                              Toast.showErrorToast(
                                "Please Upload a file below 10MB"
                              );
                            } else {
                              Toast.showErrorToast(
                                `Only .jpeg, .png, .jpg, .pdf, .doc, .docx type documents can be uploaded.`
                              );
                            }
                          } else {
                            Toast.showErrorToast("Something went wrong");
                          }
                        }}
                        accept=".jpeg, .png, .jpg, .pdf, .doc, .docx"
                        multiple={false}
                        maxSize={MAX_SIZE}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            className={classes.documentPlaceholder}
                            {...getRootProps()}
                          >
                            <Typography
                              variant="p2Dark"
                              align="center"
                              style={{ margin: 20, marginTop: 0 }}
                            >
                              Drag drop or
                            </Typography>
                            <PrimaryButton
                              wide
                              className={`${classes.uploadBtnDropzone}`}
                            >
                              Upload
                              <input {...getInputProps()} />
                            </PrimaryButton>
                            <span
                              style={{
                                fontSize: 11,
                                marginTop: 5,
                                marginLeft: 5,
                              }}
                            >
                              (supported file type docx,pdf,png,jpg)
                            </span>
                            <span
                              style={{
                                fontSize: 11,
                                marginTop: 5,
                                marginLeft: 5,
                              }}
                            >
                              (max file size 10MB)
                            </span>
                          </div>
                        )}
                      </Dropzone>
                    ) : (
                      <div
                        className="row-center"
                        style={{ marginTop: 10, marginRight: 20 }}
                      >
                        <Grid container spacing={2}>
                          {facilityData && facilityData?.aoi?.data && (
                            <Grid item>
                              <ImagePreview
                                src={getPreview(
                                  facilityData?.aoi?.filename
                                    .split(".")
                                    .reverse()[0],
                                  facilityData.aoi.data
                                )}
                                name={facilityData.aoi.filename}
                                size={facilityData.aoi.filesize}
                                id={uuidv4()}
                                onRemove={() =>
                                  handleRemoveUploadedImage("aoi")
                                }
                              />
                            </Grid>
                          )}
                          {aoi.map((image, index) => (
                            <Grid item key={index}>
                              <ImagePreview
                                file={image}
                                src={preview(image)}
                                id={uuidv4()}
                                onRemove={() => handleRemoveFrontImage(index)}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </div>
                    )}
                  </Grid>

                  {/* <Grid item xs={6} className={classes.gridItem}>
                        <span className="p2" style={{ marginTop: 22 }}>
                          Government ID(Back)
                        </span>
                        {backId.length === 0 &&
                        facilityData?.govt_id_back?.data === null ? (
                          <Dropzone
                            onDrop={(acceptedFiles, fileRejections) => {
                              if (acceptedFiles.length > 0) {
                                setBackId([...acceptedFiles]);
                              } else if (fileRejections.length > 0) {
                                Toast.showErrorToast(
                                  "Please Upload a file below 10MB"
                                );
                              }
                            }}
                            accept=".jpeg, .png, .jpg, .pdf, .doc, .docx"
                            multiple={false}
                            maxSize={MAX_SIZE}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div
                                className={classes.documentPlaceholder}
                                {...getRootProps()}
                              >
                                <Typography
                                  variant="p2Dark"
                                  align="center"
                                  style={{ margin: 20, marginTop: 0 }}
                                >
                                  Drag drop or
                                </Typography>

                                <PrimaryButton
                                  wide
                                  style={{ background: "#090930" }}
                                >
                                  Upload
                                  <input {...getInputProps()} />
                                </PrimaryButton>
                                <span
                                  style={{
                                    fontSize: 11,
                                    marginTop: 5,
                                    marginLeft: 5,
                                  }}
                                >
                                  (supported file type docx,pdf,png,jpg)
                                </span>
                                <span
                                  style={{
                                    fontSize: 11,
                                    marginTop: 5,
                                    marginLeft: 5,
                                  }}
                                >
                                  (max file size 10MB)
                                </span>
                              </div>
                            )}
                          </Dropzone>
                        ) : (
                          <div
                            className="row-center"
                            style={{ marginTop: 10, marginRight: 20 }}
                          >
                            <Grid container>
                              {facilityData &&
                                facilityData.govt_id_back?.data && (
                                  <Grid item>
                                    <ImagePreview
                                      src={getPreview(
                                        facilityData.govt_id_back.filename
                                          .split(".")
                                          .reverse()[0],
                                        facilityData.govt_id_back.data
                                      )}
                                      name={facilityData.govt_id_back.filename}
                                      size={facilityData.govt_id_back.filesize}
                                      id={uuidv4()}
                                      onRemove={() =>
                                        handleRemoveUploadedImage(
                                          "govt_id_back"
                                        )
                                      }
                                    />
                                  </Grid>
                                )}
                              {backId.map((image, index) => (
                                <Grid item key={index}>
                                  <ImagePreview
                                    file={image}
                                    src={preview(image)}
                                    id={uuidv4()}
                                    onRemove={() =>
                                      handleRemoveBackImage(index)
                                    }
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          </div>
                        )}
                      </Grid> */}
                </Grid>

                {/* Adding entity Field */}
              </Paper>
            </form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default EntityDocsComponent;
