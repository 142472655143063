import React, { useState, useEffect } from "react";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import { withStyles } from "@material-ui/core";
import CalendarIcon from "./assets/calendar.svg";

const StyledPicker = withStyles({
    root: {
        "& label.Mui-focused": {},
        "& .MuiOutlinedInput-root": {
            borderColor: "#EDECF5",
            padding: "3px 3px",
            backgroundColor: "white"
        },
        fontSize: 13,
        lineHeight: 20 / 13,
        fontWeight: 500,
        display: "none",
    },
})(KeyboardDatePicker);

const MonthYearDatepicker = ({
    id,
    label,
    value,
    onChange,
    error,
    helperText,
    onBlur,
    format = "MMMM yyyy",
    disablePast = true,
    disableFuture = false,
}) => {
    const [open, setOpen] = useState(false);
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <span
                id={id}
                onClick={() => setOpen(!open)}
                style={{ fontWeight: 600, fontSize: 20, marginTop: 4,display : "block",cursor : "pointer"  }}
            >
                {value}
            </span>
            <StyledPicker
                open={open}
                inputVariant="outlined"
                variant="dialog"
                label={label}
                format={format}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error}
                helperText={helperText}
                disableFuture={disableFuture}
                openTo="month"
                views={["month", "year"]}
                onClose={() => {
                    setOpen(false);
                }}
                autoOk
                fullWidth
                keyboardIcon={<img src={CalendarIcon} />}
            />
        </MuiPickersUtilsProvider>
    );
};

export default MonthYearDatepicker;
