import React from "react";
import LogoutIcon from "../../assets/icons/logout.svg";

const LogoutButton = ({ onClick, style }) => {
  return (
    <div style={{ ...style }} className="cursor-pointer">
      <img src={LogoutIcon} onClick={onClick} />
    </div>
  );
};

export default LogoutButton;