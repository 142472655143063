import React, { useEffect, useState } from "react";
import { makeStyles, Typography, Menu, withStyles } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Fade from "@material-ui/core/Fade";
import reversePyramidFilter_Active from "../../assets/icons/reversePyramidFilter_Active.svg";
import reversePyramidFilter_Inactive from "../../assets/icons/reversePyramidFilter_Inactive.svg";
import { Axios } from "../../../api/apiConsts";
import InfinitScroll from "react-infinite-scroll-component";
import {  CircularLoader } from "../../components";
import SearchIcon from "../Form/assets/search-line.svg";
import CrossIcon from "../Form/assets/cross-icon.svg";
import InputBase from "@material-ui/core/InputBase";

const StyledMenu = withStyles({
  root: {
    "&:hover": {
      backgroundColor: "#EDECF5",
    },
    fontSize: "12px !important",
    width: "100%",
    background: "#F3F4F7",
    marginTop: "8px !important",
    fontWeight: 700,
    textAlign: "center !important",
    padding: 10,
    borderRadius: "8px !important",
    justifyContent: "center",
  },
  selected: {
    backgroundColor: "white !important",
    color: "#FF0083 !important",
    fontWeight: 700,
    fontSize: "12px !important",
    border: "1px solid #FF0083 !important",
  },
})(MenuItem);

const SelectFilter = ({
  selectedIndex,
  setSelectedIndex,
  setSelectedValue,
  activeIcon = reversePyramidFilter_Active,
  inactiveIcon = reversePyramidFilter_Inactive,
  isTextNeeded,
  searchPlaceholder,
  searchEnable=false

}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menu, setMenue] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [query, setQuery] = useState("");

  useEffect(() => {
    getFacilityList();
  }, [page, query]);
  useEffect(() => {
    if (query) {
      setPage(1);
    }
  }, [query]);
  // <============ All Helper Functions

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    if (index === selectedIndex) {
      setSelectedIndex(null);
      setSelectedValue(null);
      setAnchorEl(null);
    } else {
      setSelectedIndex(index);
      setSelectedValue(menu[index].id);
      setAnchorEl(null);
    }
  };

  const getMenus = () => {
    return (
      <InfinitScroll
        dataLength={menu?.length}
        next={fetchNextUsers}
        hasMore={menu?.length === count ? false : true}
        scrollThreshold="20px"
        loader={<CircularLoader />}
        scrollableTarget="scrollableDiv"
        height={290}
      >
        {menu.map((e, index) => {
          return (
            <StyledMenu
              key={index}
              selected={index === selectedIndex}
              onClick={(event) => handleMenuItemClick(event, index)}
            >
              {e.name}
            </StyledMenu>
          );
        })}
      </InfinitScroll>
    );
  };
  // <========== Variables
  const getFacilityList = () => {
    Axios.get(
      `/facility?search=${query}&page_size=${10}&page=${page}&is_active=${true}`
    )
      .then((response) => {
        if (response.data.statusCode === 200) {
          setCount(response?.data?.data.count);

          let dummyMenue = response.data.data.results.map((facility) => {
            return {
              name: facility.name,
              id: facility.id,
            };
          });
          if (page === 1) {
            setMenue(dummyMenue);
          } else {
            setMenue([...menu, ...dummyMenue]);
          }
        }
      })
      .catch((error) => {});
  };
  const fetchNextUsers = () => setPage(page + 1);
  const open = Boolean(anchorEl);
  const classes = useStyles();
  return (
    <>
      <img
        src={
          typeof(selectedIndex) === 'number' 
            ? activeIcon
            : inactiveIcon
        }
        className={`${classes.pyramid} cursor-pointer`}
        onClick={handleIconClick}
        aria-controls="fade-menu"
        aria-haspopup="true"
      />

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        getContentAnchorEl={null}
        onClose={handleClose}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: classes.paperMenu,
        }}
      >
        {searchEnable && (
          <div
            className="row-center"
            style={{
              height: 42,
            }}
          >
            <img src={SearchIcon} style={{ marginLeft: 20, marginRight: 10 }} />
            <InputBase
              value={query}
              placeholder={searchPlaceholder}
              onChange={(e) => setQuery(e.target.value)}
              fullWidth
              style={{
                fontSize: 13,
                lineHeight: 20 / 13,
                fontWeight: 500,
              }}
            />
            {query !== "" && (
              <div
                onClick={() => {
                  setQuery("");
                }}
                className="cursor-pointer"
              >
                <img
                  src={CrossIcon}
                  style={{ marginBottom: -5, marginRight: 10 }}
                />
              </div>
            )}
          </div>
        )}
        {getMenus()}
      </Menu>

      {isTextNeeded && (
        <Typography className={`${classes.title}`}>{`${
          menu[selectedIndex] || ""
        }`}</Typography>
      )}
    </>
  );
};

export default SelectFilter;

const useStyles = makeStyles({
  pyramid: {
    marginLeft: 10,
  },
  paperMenu: {
    width: "250px !important",
    padding: "4px !important",
    "& .MuiList-padding": {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  title: {
    fontWeight: "600",
    fontSize: "26px",
    marginLeft: 10,
    color: "#FF0083",
    "@media (max-width:1000px)": {
      fontSize: 23,
    },
    "@media (max-width:800px)": {
      fontSize: 20,
    },
  },
});
