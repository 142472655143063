import PDFUploaded from "../App/assets/images/pdf-uploaded.svg";
import DOCUploaded from "../App/assets//images/doc-uploaded.svg";

const ellipsizeText = (text, outputLength = 30) => {
  if (text !== undefined && text.length > outputLength) {
    return text.substring(0, outputLength) + "...";
  } else {
    return text;
  }
};

const capitalizeFirstLetter = (string) => {
  if (string) {
    var newStr = string.replace(/_/g, " ");
    return newStr.charAt(0).toUpperCase() + newStr.slice(1).toLowerCase();
  }
};

const preview = (image) => {
  let icon = "";
  if (
    image.type === "image/jpg" ||
    image.type === "image/png" ||
    image.type === "image/jpeg"
  ) {
    icon = null;
  } else if (image.type === "application/pdf") {
    icon = PDFUploaded;
  } else if (
    image.type === "application/msword" ||
    image.type ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  ) {
    icon = DOCUploaded;
  }
  return icon;
};

const inputLength = (value, length) => {
  return Math.max(0, parseInt(value)).toString().slice(0, length);
};

export { ellipsizeText, preview, inputLength, capitalizeFirstLetter };
