import React, { Component, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loadable from "react-loadable";
import { ThemeProvider } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Loader from "../App/components/Loader";
import AuthWrapper from "../hoc/AuthWrapper";
import VideoWrapper from "../hoc/AuthWrapper";
import publicRoutes from "./publicRoutes";

import "./theme/index.scss";
import { Axios, LAxios } from "../api/apiConsts";
import theme from "./theme/appTheme";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Toast } from "./components";
import StorageManager from "../storage/StorageManager";
import { API_TOKEN, DEVICE_TOKEN, ROLE } from "../storage/StorageKeys";
import { logoutSuccess } from "../redux/actions/authActions";
import { createBrowserHistory } from "history";
import VideoChat from "./pages/VideoChat";
import { handlePermission } from "../APN";
import { setupToken } from "../firebase";

const history = createBrowserHistory();
const Layout = Loadable({
  loader: () => import("./layout"),
  loading: Loader,
});

const Router = () => {
  const authPages = publicRoutes.map((route, index) => {
    return route.component ? (
      <Route
        key={index}
        path={route.path}
        exact={route.exact}
        component={route.component}
      />
    ) : null;
  });

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Redirect exact from="/" to="/dashboard" />
        {authPages}
        <VideoWrapper exact={false} path="/video-chat" component={VideoChat} />
        <AuthWrapper path="/" component={Layout} />
      </Switch>
    </Suspense>
  );
};

class App extends Component {
  componentDidMount = () => {
    // Create the script tag, set the appropriate attributes

    var script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_KEY}&callback=initMap`;
    script.defer = true;

    // Attach your callback function to the `window` object
    window.initMap = function () {
      // JS API is loaded and available
    };

    // Append the 'script' element to 'head'
    document.head.appendChild(script);
    Axios.interceptors.request.use(
      (config) => {
        config.headers.authorization = `Bearer ${StorageManager.get(
          API_TOKEN
        )}`;
        return config;
      },
      (error) => Promise.reject(error)
    );

    LAxios.interceptors.request.use(
      (config) => {
        config.headers.authorization = `Bearer ${StorageManager.get(
          API_TOKEN
        )}`;
        return config;
      },
      (error) => Promise.reject(error)
    );
    Axios.interceptors.response.use(
      function (response) {
        // Do something with response data
        return response;
      },
      function (error) {
        // Do something with response error

        if (error?.response?.status === 500) {
          Toast.showErrorToast("Something Went Wrong!");
        }
        if ([401, 403].includes(error?.response?.status)) {
          history.push("/auth/login");
          window.location.reload();
          localStorage.clear();
          this.props.logoutSuccess();
        }
        return Promise.reject(error);
      }
    );
    if (window.safari && StorageManager.get(API_TOKEN)) {
      let data = window.safari.pushNotification.permission(
        "web.com.admin.goagalia"
      );
      if (data.permission === "granted") {
        handlePermission(this.updateNotificationToken);
      }
    } else if (StorageManager.get(API_TOKEN)) {
      this.updateNotificationToken();
    }
    // this.handleRoleData();
  };

  updateNotificationToken = async (token) => {
    let packet;
    if (window.safari) {
      packet = {
        device_token: token,
        is_safari: true,
        is_safari_permit: true,
      };
    } else {
      packet = {
        device_token: await setupToken(),
      };
    }
    if (
      StorageManager.get(DEVICE_TOKEN) !== packet.device_token &&
      packet.device_token
    ) {
      StorageManager.put(DEVICE_TOKEN, packet.device_token);

      LAxios.patch(`auth/user/update-device-token`, packet)
        .then((response) => {
          if (response.statusCode === 200) {
            console.log("Notfication Enabled Successfully!");
          }
        })
        .catch((error) => {
          if (error.response.status !== 500) {
            if ([400, 405, 422, 403].includes(error.response.data.statusCode)) {
              console.log(error.response.data.error.message[0]);
            }
          }
        });
    }
  };

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Router />
        <ToastContainer />
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};
const mapDispatchToProps = (dispatch) => ({
  logoutSuccess: () => dispatch(logoutSuccess()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
