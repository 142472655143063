import React from "react";
import { LocalVideoTrack } from "twilio-video";
import VideoTrack from "../VideoTrack/VideoTrack";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import AvatarIcon from "../../icons/AvatarIcon";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatarContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "black",
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1,
      margin: "0 auto",
      maxWidth: 800,
      [theme.breakpoints.down("sm")]: {
        "& svg": {
          transform: "scale(0.7)",
        },
      },
    },
  })
);

export default function MainParticipant() {
  const { room } = useVideoContext();
  const { localTracks } = useVideoContext();
  const classes = useStyles();

  const videoTrack =
    (localTracks?.find(
      (track) => !track.name.includes("screen") && track.kind === "video"
    ) as LocalVideoTrack) ?? null;
  return (
    /* audio is disabled for this participant component because this participant's audio 
       is already being rendered in the <ParticipantStrip /> component.  */
    videoTrack ? (
      <VideoTrack track={videoTrack} isLocal />
    ) : (
      <div className={classes.avatarContainer}>
        <AvatarIcon />
      </div>
    )
  );
}
