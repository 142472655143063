import React from "react";
import clsx from "clsx";
import Participant from "../Participant/Participant";
import "../../room.scss";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import useMainParticipant from "../../hooks/useMainParticipant/useMainParticipant";
import useParticipants from "../../hooks/useParticipants/useParticipants";
import useSelectedParticipant from "../VideoProvider/useSelectedParticipant/useSelectedParticipant";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      overflowY: "auto",
      // background: 'rgb(79, 83, 85)',
      // gridArea: '1 / 2 / 1 / 3',
      zIndex: 5,
    },
    transparentBackground: {
      background: "transparent",
    },
    scrollContainer: {
      display: "flex",
      justifyContent: "center",
    },
    innerScrollContainer: {
      width: "100%",
      height: "100%",
      position: "absolute",
      // padding: '1.5em 0',
    },
  })
);

export default function ParticipantList() {
  const classes = useStyles();
  const participants = useParticipants();
  const [
    selectedParticipant,
    setSelectedParticipant,
  ] = useSelectedParticipant();
  const mainParticipant = useMainParticipant();
  //if (participants.length === 0) return null; // Don't render this component if there are no remote participants.





  return (
    <aside className={clsx(classes.container)}>
      <div className={classes.scrollContainer}>
        <div className={classes.innerScrollContainer}>
          {/* <Participant participant={localParticipant} isLocalParticipant={true} /> */}
          {participants.map((participant) => {
            return (
              <Participant
                key={participant.sid}
                participant={participant}
                isSelected={participant === selectedParticipant}
                onClick={() => setSelectedParticipant(participant)}
              />
            );
          })}
          {participants.length === 0 && (
            <div className="remote-waiting">
              <div>
                <span className="session-begin">
                  Your session will begin shortly
                </span>
                <p className="session-note">
                  Please hold while the other party joins.
                </p>
              </div>
            </div>
          )}
          
        </div>
      </div>
    </aside>
  );
}
