import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core';
import ParticipantList from '../ParticipantList/ParticipantList';
import MainParticipant from '../MainParticipant/MainParticipant';

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      position: 'relative',
      height: '100%',
      display: 'grid',
      gridTemplateColumns: `1fr ${theme.sidebarWidth}px`,
      gridTemplateRows: '100%'
    },
    imgContent:{
      marginLeft: "4%",
      height:160,
      width:205,
      position: "absolute",
      bottom: "6%",
      zIndex: 100
    },
    rightDrawerOpen: { gridTemplateColumns: `1fr ${theme.sidebarWidth}px ${theme.rightDrawerWidth}px` },
  };
});

export default function Room() {
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.container)}
    >
       <div className={clsx(classes.imgContent)}>
      
      <MainParticipant />
      </div>
     <ParticipantList />
    </div>
  );
}
