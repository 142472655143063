import TWILIO_TYPES from "../types/twilioTypes";

const initialState = {
  client:null,
  client_token:null
};

export default function facilityReducer(state = initialState, action) {
  switch (action.type) {
    case TWILIO_TYPES.UPDATE_CLIENT:
      return {
        ...state,
        client: action.payload,
       
      };
      case TWILIO_TYPES.UPDATE_TOKEN:
          return {
              ...state,
              client_token:action.payload
          };
      case TWILIO_TYPES.SESSION_LOGOUT:
        return  {
          client:null,
          client_token:null
        }    
    default:
      return state;
  }
}
