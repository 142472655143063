import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  InputField,
  ImageViewer as ImagePreview,
  Select,
  Datepicker,
} from "../../components";
import { Grid, makeStyles } from "@material-ui/core";
import moment from "moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { FormControl, FormControlLabel } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import { inputLength } from "../../../utils/textUtils";
const entity_types = [
  {
    label: "Limited Liability Company",
    value: "LLC",
  },
  {
    label: "Association",
    value: "ASSOCIATION",
  },
  {
    label: "Corporation (C-Corp, S-Corp, B-Corp)",
    value: "CORP",
  },
  {
    label: "Partnership",
    value: "PARTNERSHIP",
  },
  {
    label: "Sole Proprietorship",
    value: "SOLE-PROPRIETORSHIP",
  },
  {
    label: "Business Trust",
    value: "TRUST",
  },
  {
    label: "Business Vendor",
    value: "VENDOR",
  },
  {
    label: "Estate",
    value: "ESTATE",
  },
  {
    label: "Individual Retirement Accounts",
    value: "IRA",
  },
];

const EntityDetailsComponent = ({
  savedDetails,
  header,
  states,
  countries,
  setEntityDetails,
  submitClicked,
  fromEdit = false,
}) => {
  useEffect(() => {
    if (submitClicked) {
      handleSubmit();
    }
  }, [submitClicked]);
  const {
    entity_legal_name,
    ein_number,
    inCopDate,
    entity_type,
    incorporation_state,
  } = savedDetails || {};

  const formik = useFormik({
    initialValues: {
      entity_legal_name: entity_legal_name || "",
      ein_number: ein_number || "",
      inCopDate: inCopDate ? new Date(inCopDate) : null,
      entity_type: { name: entity_type?.name || "" },
      incorporation_state: { id: incorporation_state?.id || "" },
    },
    validationSchema: Yup.object().shape({
      entity_legal_name: Yup.string().required("Legal name is required."),

      ein_number: Yup.string()
        .required("EIN Number is required")
        .test("valid", "Please enter a valid EIN number", (val) => {
          if (val) {
            return [...val].every((c) => "0123456789".includes(c));
          }
        })
        .test("len", "EIN Number should be 9 digits long", (val) => {
          if (val) {
            return val.length === 9;
          }
        }),
      inCopDate: Yup.date()
        .nullable()
        .required("Date of Incorporation is required."),
      entity_type: Yup.object().shape({
        name: Yup.string().required("Entity type is required"),
      }),
      incorporation_state: Yup.object().shape({
        id: Yup.string().required("Incorporation state is required"),
      }),
    }),
    onSubmit: (values) => {
      // Do something with values
      //  addBenefit(values);
    },
  });
  const {
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
  } = formik || {};
  const classes = useStyles();

  useEffect(() => {
    setEntityDetails("entityDetails", values);
  }, [values?.dob]);
  return (
    <div style={{ marginTop: 24 }}>
      <span className="p2" style={{ fontWeight: 400 }}>
        Entity Detials
      </span>
      <Grid container spacing={3} style={{ marginTop: 12 }}>
        <Grid item xs={12}>
          <InputField
            type="text"
            label="Entity Legal Name*"
            variant="outlined"
            fullWidth
            value={values.entity_legal_name}
            onChange={handleChange("entity_legal_name")}
            onBlur={() => {
              setEntityDetails("entityDetails", values);
              setFieldTouched("entity_legal_name", true);
              handleBlur("entity_legal_name");
            }}
            error={touched.entity_legal_name && errors.entity_legal_name}
            helperText={touched.entity_legal_name && errors.entity_legal_name}
            noMinHeight
          />
        </Grid>
        <Grid item xs={6}>
          <InputField
            type="number"
            label="EIN number*"
            variant="outlined"
            fullWidth
            value={values.ein_number}
            onChange={handleChange("ein_number")}
            onBlur={() => {
              setEntityDetails("entityDetails", values);
              setFieldTouched("ein_number", true);
              handleBlur("ein_number");
            }}
            error={touched.ein_number && errors.ein_number}
            helperText={touched.ein_number && errors.ein_number}
            onInput={(e) => {
              e.target.value = inputLength(e.target.value, 9);
            }}
            noMinHeight
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" fullWidth={true}>
            <Select
              id="entity_type"
              label="Entity Type*"
              items={entity_types}
              fullWidth
              value={values.entity_type.name}
              onChange={(value) => {
                setFieldValue("entity_type", { name: value });

                // handleStateID(value);
              }}
              onBlur={() => {
                setEntityDetails("entityDetails", values);

                handleBlur("entity_type");
                setFieldTouched("entity_type", true);
              }}
              error={touched.entity_type && errors.entity_type}
              helperText={touched.entity_type && errors.entity_type?.name}
              noMinHeight
            />
            <FormHelperText style={{ color: "#ff0040" }}>
              {touched.entity_type && errors?.entity_type?.name}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <FormControl variant="outlined" fullWidth={true}>
              <Datepicker
                style={{ minHeight: "unset !important" }}
                fullWidth
                inputVariant="outlined"
                label="Date of Incorporation*"
                format="MM/dd/yyyy"
                value={values.inCopDate}
                onChange={(date) => {
                  setFieldValue("inCopDate", date);
                }}
                disableFuture
                onBlur={() => {
                  setEntityDetails("entityDetails", values);
                  setFieldTouched("inCopDate", true);
                  handleBlur("inCopDate");
                }}
                placeholder="MM/dd/yyyy"
                noMinHeight
              ></Datepicker>
              <FormHelperText style={{ color: "#ff0040" }}>
                {touched.inCopDate && errors?.inCopDate}
              </FormHelperText>
            </FormControl>
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item xs={6}>
          <FormControl variant="outlined" fullWidth={true}>
            <Select
              id="incorporation_state"
              label="Incorporation State*"
              items={states}
              fullWidth
              value={values.incorporation_state.id}
              onChange={(value) => {
                setFieldValue("incorporation_state", { id: value });
                // handleStateID(value);
              }}
              onBlur={() => {
                setEntityDetails("entityDetails", values);

                handleBlur("incorporation_state");
                setFieldTouched("incorporation_state", true);
              }}
              error={touched.incorporation_state && errors.incorporation_state}
              helperText={
                touched.incorporation_state && errors.incorporation_state
              }
              noMinHeight
            />
            <FormHelperText style={{ color: "#ff0040" }}>
              {touched.incorporation_state && errors?.incorporation_state?.id}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};
export default EntityDetailsComponent;
const useStyles = makeStyles((theme) => ({}));
