import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import useParticipants from "../hooks/useParticipants/useParticipants";
import useRoomState from '../hooks/useRoomState/useRoomState';
import useVideoContext from "../hooks/useVideoContext/useVideoContext";


const useStyles = makeStyles({
  headerContainer: (props) => ({
    background:
      "linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.2) 100%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

  }),
  headerContent: {
    display: "flex",
    padding: "30px 30px",
    zIndex: 12,
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    background: "background: linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.2) 100%)"
  },

  userName: {
    color: "#FFFFFF",
    fontFamily: "Manrope",
    fontSize: 18,
    fontWeight: "bold",
    zIndex: 12,
  },
  roleName: {
    fontSize: 11,
    lineHeight: 15 / 11,
    color: "#9EA4BB",
    marginRight: 10,
  },
  timer: {
    boxSizing: "border-box",
    height: 31,
    width: 121,
    border: "1px solid #FFFFFF",
    borderRadius: 15,
    backgroundColor: "#FFFFFF",
    color: "#222222",
    fontFamily: "Manrope",
    fontSize: 14,
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex:12,
    paddingLeft:10,
    paddingRight:10
  },
  timerContent: {},
});


const Header = (props) => {
  const history = useHistory();
  const { room } = useVideoContext();
  const [width, setWidth] = React.useState(window.innerWidth);
  const [timerOn, setTimer] = React.useState(false);
  const [timerStart, setTimerStart] = React.useState(0);
  const [sessionEnded, setSessionEnded] = React.useState(false);
  const [timerTime, setTimerTime] = React.useState(0);
  const participants = useParticipants();
  const roomState = useRoomState();
  let timer;
  const startTimer = () => {
    setTimer(true);
    setTimerTime(timerTime);
    setTimerStart(Date.now() - timerTime);
    timer = setInterval(() => {
      setTimerTime((time) => time + 10);
    }, 10);
  };
  const stopTimer = () => {
    setTimer(false);
    clearInterval(timer);
  };

  React.useEffect(() => {
    if (participants.length > 0) {
      startTimer();
    }
    if (roomState === "disconnected") {
      stopTimer();
    }

    return () => stopTimer();
  }, [participants, roomState]);

  React.useEffect(() => {
    if (room?.state === "disconnected") {
      setSessionEnded(true);
    }
    if (participants.length > 0 && room === null) {
      setSessionEnded(true);

    }
  }, [participants, room]);


  const classes = useStyles();
  // console.log(roomState);
  // console.log(timerOn);
  let seconds = ("0" + (Math.floor(timerTime / 1000) % 60)).slice(-2);
  let minutes = ("0" + (Math.floor(timerTime / 60000) % 60)).slice(-2);
  let hours = ("0" + Math.floor(timerTime / 3600000)).slice(-2);
  // console.log(participants,roomState,"room stattetetet")
  return (
    <div className={classes.headerContainer}>
      <div className={classes.headerContent}>
        <div className={classes.timer}>
          {sessionEnded ? "Disconnected":!timerOn ? "Awaiting..." : `${hours}: ${minutes} : ${seconds}`}
        </div>
        <div>
          <div className={classes.userName}>{props.name}</div>
        </div>
      </div>
    </div>
  );
};

export default Header;
