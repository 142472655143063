import React, { useState, useEffect } from "react";
import useConnectionOptions from "./utils/useConnectionOptions/useConnectionOptions";
import ErrorDialog from "./components/ErrorDialog/ErrorDialog";
import { VideoProvider } from "./components/VideoProvider";
import { useAppState } from "./state";
import App from "./App";
import { Axios } from "../../../api/apiConsts";
import ChatHeader from "./header/ChatHeader";
import { isPermissionDenied } from "./utils";
import MediaErrorSnackbar from "./components/MediaErrorSnackbar/MediaErrorSnackbar";

const VideoApp = (props: any) => {
  const connectionOptions = useConnectionOptions();
  const [token, setToken] = useState(null);
  const { error, setError } = useAppState();
  const [mediaError, setMediaError] = React.useState<Error>();
  let timer;
  const checkPermission = async () => {
    // In Chrome, it is possible to deny permissions to only audio or only video.
    // If that has happened, then we don't want to attempt to acquire the device.
    const isCameraPermissionDenied = await isPermissionDenied("camera");
    const isMicrophonePermissionDenied = await isPermissionDenied("microphone");
    // These custom errors will be picked up by the MediaErrorSnackbar component.
    if (isCameraPermissionDenied && isMicrophonePermissionDenied) {
      throw new Error("Permissions Denied");
    }
    if (isCameraPermissionDenied) {
      throw new Error("Camera Permission Denied");
    }
    if (isMicrophonePermissionDenied) {
      throw new Error("Microphone Permission Denied");
    }

    if (
      isCameraPermissionDenied === false &&
      isMicrophonePermissionDenied === false
    ) {
      fetchToken(props.nurseId);
    }
  };

  useEffect(() => {
    checkPermission().catch((error) => {
      setMediaError(error);
      //if camera and microphone permission denied then close the current tab
      timer = setTimeout(function () {
        window.top.close();
      }, 5000);
    });

    return ()=>clearTimeout(timer);
  }, []);

  const fetchToken = async (id: String) => {
    if (!sessionStorage.getItem("video_token")) {
      const resp = await Axios.post("credential/video-call", {
        nurse: id,
      });
      if (resp.data.success) {
        setToken(resp.data.data.token);
        sessionStorage.setItem("video_token", resp.data.data.token);
      }
    } else {
      setToken(sessionStorage.getItem("video_token"));
    }
  };
  // console.log(token);
  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      <ChatHeader name={props.name} loader={props.loader}></ChatHeader>
      <MediaErrorSnackbar error={mediaError} />
      <ErrorDialog dismissError={() => setError(null)} error={error} />
      {token && <App token={token} />}
    </VideoProvider>
  );
};

export default VideoApp;
