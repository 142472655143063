import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import { isMobile } from '../../utils';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Typography, Grid, Hidden } from '@material-ui/core';
import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../Buttons/ToggleVideoButton/ToggleVideoButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      // backgroundColor: theme.palette.background.default,
      bottom: 0,
      left: 0,
      right: 0,
      height:120,
      position: 'fixed',
      display: 'flex',
      padding: '0 1.43em',
      zIndex: 10
    },
    hideMobile: {
      display: 'initial',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  })
); 

export default function MenuBar() {
  const classes = useStyles();
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';
  const { room } = useVideoContext();

  return (
    <>
      <footer className={classes.container}>
        <Grid container justify="space-around" alignItems="center">
          <Grid item>
            <Grid container justify="center">
              <div style={{marginRight:20}}>
              <ToggleAudioButton  />
              </div>
              <div style={{marginRight:20}}>
              <EndCallButton />
              </div>
              <div style={{marginRight:20}}>
              <ToggleVideoButton disabled={isReconnecting} />
              </div>
            </Grid>
          </Grid>
          
        </Grid>
      </footer>
    </>
  );
}
