import React from "react";

const FacilityCard = (props) => {
  return (
    <div
      className= "fd-card" 
      id={props.id}
      style={{
        width: props.size === "small" ? "18%" : "28.5%",
        cursor: "pointer",
        marginRight: 10,
        marginBottom: 10,
        ...props.style,
      }}
      onClick={props.onClick}
    >
      <div className="fd-card-icon">
        <img src={props.icon} alt="facility icon" />
      </div>
      <div className="fd-card-title">{props.title}</div>
      <div className="fd-card-count">{props.count}</div>
    </div>
  );
};

export default FacilityCard;
