import React from "react";
import CrossIcon from "../../assets/icons/cross_icon.svg";
import SecondaryButton from "./secondaryButton";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  closeBtn: {
    width: "110px",
    height: "44px",
    padding: "0",
    fontWeight: "700",
    border: "1px solid #DDDFE6",
    borderRadius: "4px",
    "@media (max-width:940px)": {
      width: "80px",
      height: "38px",
    },
  },
  cross: {
    marginLeft: 15,
  },
});
const CloseCrossButton = ({ onClick, btnClass }) => {
  const classes = useStyles();
  return (
    <SecondaryButton
      onClick={onClick}
      className={`${classes.closeBtn} ${classes.cross} ${btnClass}`}
    >
      Close
      <img src={CrossIcon} className={classes.cross} alt="." />
    </SecondaryButton>
  );
};

export default CloseCrossButton;
