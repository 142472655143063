import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  InputField,
  Select,
  CheckBoxRectangle,
  PhoneInputField,
  Datepicker,
  SsnInputField,
  Loader,
} from "../../components";
import { Grid, makeStyles } from "@material-ui/core";
import { FormControl, FormControlLabel } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import FormHelperText from "@material-ui/core/FormHelperText";
import { LAxios } from "../../../api/apiConsts";
const OwnerLegalDetails = ({
  savedAdress,
  header,
  isSameActive,
  sameTxt,
  updateKey,
  setEntityDetails,
  getCpyAdress,
  submitClicked,
  showSsn = false,
  fromEdit = false,
}) => {
  const [adressToCopied, setAdressToCopied] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (submitClicked) {
      handleSubmit();
    }
  }, [submitClicked]);
  useEffect(() => {
    if (isChecked) {
      setAdressToCopied(getCpyAdress());
      setEntityDetails(updateKey, getCpyAdress());
    }
  }, [isChecked]);
  useEffect(() => {
    resetForm();
  }, [adressToCopied]);

  const mobileErrorHandlerNew = (value, setFieldTouched, setFieldError) => {
    if (value.substring(2).replace(/\D/g, "").length === 10) {
      setLoading(true);
      LAxios.get(`common/check-mobile/+1/${value.replace(/\D/g, "")}`)
        .then((res) => {
          if (res.data) {
            if (!showSsn && res?.data?.data?.is_exists) {
              //for legal mobile number same number can exist.
              //showssn only true if component is called for legal info
              setFieldError("phone", "Mobile Number Already Exist");
            } else if (res?.data?.data.is_invalid) {
              setFieldError("phone", "Invalid Mobile Number");
            }
            setFieldTouched("phone", true, false);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const { legal_first_name, legal_last_name, dob, mobile, email, gender, ssn } =
    Object.keys(adressToCopied).length !== 0 ? adressToCopied : savedAdress;
  const handleChcekrd = () => setIsChecked(!isChecked);
  const formik = useFormik({
    enableReinitialize: fromEdit ? fromEdit : isChecked,
    initialValues: {
      first_name: legal_first_name || "",
      last_name: legal_last_name || "",
      email: email || "",
      phone: mobile || "",
      gender: { name: gender ? (gender?.name ? gender.name : gender) : "" },
      dob: dob ? new Date(dob) : null,
      ssn: ssn || "",
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required("First Name is required"),
      last_name: Yup.string().required("Last Name is required"),
      email: Yup.string().email("Invalid email").required("Email is required"),
      phone: Yup.string()
        .required("Phone Number is required")
        .test(
          "is-invalid-number",
          "Please enter correct phone number",
          (val) => {
            if (
              Number(val) !== Number.NaN &&
              Number(val).toString().length === 10
            ) {
              return true;
            } else if (val) {
              return val.substring(2).replace(/\D/g, "").length === 10;
            }
          }
        ),

      gender: Yup.object().shape({
        name: Yup.string().required("Gender is required"),
      }),
      dob: Yup.date()
        .required("Please enter your Date of Birth")
        .nullable()
        .typeError("Invalid Date")
        .test(
          "birthday",
          "Employee Should be atleast 18 years old",
          function (val) {
            const ageDifMs = Date.now() - val;
            const ageDate = new Date(ageDifMs); // miliseconds from epoch
            const absoluteVal = Math.abs(ageDate.getUTCFullYear() - 1970);
            return absoluteVal >= 18;
          }
        ),
      ssn: Yup.string()
        .required("SSN is required")
        .test("is-valid-ssn", "Please enter correct SSN number", (val) => {
          var patt = new RegExp(
            "^(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}"
          );
          var res = patt.test(val);
          if (!res) {
            return false;
          }
          return true;
        }),
    }),
    onSubmit: () => {
      // Do something with values
    },
  });
  const {
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    setFieldError,
    resetForm,
  } = formik || {};

  useEffect(() => {
    setEntityDetails(updateKey, values);
  }, [values?.dob]);
  const classes = useStyles();
  return (
    <div style={{ marginTop: 32 }}>
      {loading && <Loader />}
      {header && (
        <span className="p2" style={{ fontWeight: 400 }}>
          {header}
        </span>
      )}
      {isSameActive && (
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <CheckBoxRectangle
                checked={isChecked}
                onChange={handleChcekrd}
                name="checkedG"
              />
            }
            label={`Same as the ${sameTxt}`}
          />
        </Grid>
      )}
      <Grid container spacing={3} style={!isSameActive ? { marginTop: 8 } : {}}>
        <Grid item xs={6}>
          <InputField
            type="text"
            label="First Name*"
            variant="outlined"
            fullWidth
            value={values.first_name}
            onChange={handleChange("first_name")}
            onBlur={() => {
              setEntityDetails(updateKey, values);
              setFieldTouched("first_name", true);
              handleBlur("first_name");
            }}
            error={touched.first_name && errors.first_name}
            helperText={touched.first_name && errors.first_name}
            noMinHeight
            disabled={isChecked}
          />
        </Grid>
        <Grid item xs={6}>
          <InputField
            type="text"
            label="Last Name*"
            variant="outlined"
            fullWidth
            value={values.last_name}
            onChange={handleChange("last_name")}
            onBlur={() => {
              setEntityDetails(updateKey, values);
              setFieldTouched("last_name", true);
              handleBlur("last_name");
            }}
            error={touched.last_name && errors.last_name}
            helperText={touched.last_name && errors.last_name}
            noMinHeight
            disabled={isChecked}
          />
        </Grid>

        <Grid item xs={6}>
          <InputField
            type="text"
            label="Email*"
            variant="outlined"
            fullWidth
            value={values.email}
            onChange={handleChange("email")}
            onBlur={() => {
              setEntityDetails(updateKey, values);
              setFieldTouched("email", true);
              handleBlur("email");
            }}
            error={touched.email && errors.email}
            helperText={touched.email && errors.email}
            noMinHeight
            disabled={isChecked}
          />
        </Grid>
        <Grid item xs={6}>
          <PhoneInputField
            id="phone"
            type="text"
            label="Phone Number*"
            variant="outlined"
            value={values.phone}
            error={touched.phone && errors.phone}
            helperText={touched.phone && errors.phone}
            onChange={handleChange("phone")}
            onBlur={() => {
              setEntityDetails(updateKey, values);
              setFieldTouched("phone", true);
              handleBlur("phone");
              mobileErrorHandlerNew(
                values.phone,
                handleBlur,
                setFieldTouched,
                setFieldError
              );
            }}
            noMinHeight
            disabled={isChecked}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" fullWidth>
            <Select
              id="gender"
              label="Gender*"
              items={[
                { value: "M", label: "Male" },
                { value: "F", label: "Female" },
                { value: "O", label: "Others" },
                {
                  value: "NOT_KNOWN",
                  label: "Do not wish to specify",
                },
              ]}
              fullWidth
              value={values.gender.name}
              onChange={(value) => {
                setFieldValue("gender", { name: value });

                // handleStateID(value);
              }}
              onBlur={() => {
                setEntityDetails(updateKey, values);
                setFieldTouched("gender", true);
                handleBlur("gender");
              }}
              error={touched.gender && errors.gender}
              helperText={touched.gender && errors.gender}
              isDisabled={isChecked}
              noMinHeight
            ></Select>
            <FormHelperText style={{ color: "#ff0040" }}>
              {touched.gender && errors?.gender?.name}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <FormControl variant="outlined" fullWidth>
              <Datepicker
                style={{ minHeight: "unset !important" }}
                inputVariant="outlined"
                label="Date of Birth*"
                format="MM/dd/yyyy"
                placeholder="MM/dd/yyyy"
                value={values.dob}
                onChange={(date) => {
                  setFieldValue("dob", date);
                }}
                onBlur={() => {
                  setEntityDetails(updateKey, values);
                  setFieldTouched("dob", true);
                  handleBlur("dob");
                }}
                fullWidth
                maxDate={
                  new Date(
                    new Date().setFullYear(new Date().getFullYear() - 18)
                  )
                }
                error={false}
                helperText={null}
                disabled={isChecked}
              ></Datepicker>
              <FormHelperText style={{ color: "#ff0040" }}>
                {touched.dob && errors?.dob}
              </FormHelperText>
            </FormControl>
          </MuiPickersUtilsProvider>
        </Grid>
        {showSsn && (
          <Grid item xs={6} className={classes.gridItem}>
            <SsnInputField
              label="Social Security Number(SSN)*"
              variant="outlined"
              fullWidth
              value={values.ssn}
              onChange={(e) => {
                setFieldValue("ssn", e.target.value);
              }}
              onBlur={() => {
                setEntityDetails(updateKey, values);
                setFieldTouched("ssn", true);
                handleBlur("ssn");
              }}
              error={touched.ssn && errors.ssn}
              helperText={touched.ssn && errors.ssn}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};
export default OwnerLegalDetails;
const useStyles = makeStyles(() => ({}));
