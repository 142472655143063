import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  InputField,
  ImageViewer as ImagePreview,
  Select,
  CheckBoxRectangle,
  PrimaryButton,
} from "../../components";
import { Grid, makeStyles } from "@material-ui/core";
import { FormControl, FormControlLabel } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import LocationIcon from "../../assets/icons/marker.svg";
import { getAddress, getLatLngByAddress } from "../../../utils/locationUtils";
import GoogleMapReact from "google-map-react";

const AddressComponent = ({
  savedAdress,
  header,
  states,
  countries,
  isSameActive,
  sameTxt,
  updateKey,
  setEntityDetails,
  getCpyAdress,
  submitClicked,
  fromEdit = false,
  showLocate = false,
  addressToBeCopied,
}) => {
  const [adressToCopied, setAdressToCopied] = useState({});
  const [isChecked, setIsChecked] = useState(
    savedAdress?.isChecked ? true : false
  );
  const [location, setLocation] = useState({
    latitude: 0,
    longitude: 0,
  });

  useEffect(() => {
    if (submitClicked) {
      handleSubmit();
    }
  }, [submitClicked]);

  useEffect(() => {
    if (isChecked && addressToBeCopied) {
      setAdressToCopied({ ...getCpyAdress() });
      setEntityDetails(updateKey, { ...getCpyAdress(), isChecked: true });
    }
  }, [isChecked, addressToBeCopied]);

  useEffect(() => {
    resetForm();
  }, [adressToCopied]);

  useEffect(() => {
    setEntityDetails(updateKey, { ...values, ...location });
  }, [location]);

  const { address_line1, address_line2, city, state, zipcode, country } =
    Object.keys(adressToCopied).length !== 0 ? adressToCopied : savedAdress;

  const handleChcekrd = () => {
    setIsChecked(!isChecked);
  };

  const updateLocation = async (address) => {
    const latLng = await getLatLngByAddress(address);
    if (latLng) {
      setLocation({ latitude: latLng.lat, longitude: latLng.lng });
    }
  };

  const formik = useFormik({
    enableReinitialize: fromEdit ? fromEdit : isChecked,
    initialValues: {
      address_line1: address_line1 || "",
      address_line2: address_line2 || "",
      city: city || "",
      state: { id: state?.id || "" },
      zipcode: zipcode || "",
      country: {
        name: country?.name || "",
      },
      latitude: location.latitude,
      longitude: location.longitude,
    },
    validationSchema: Yup.object().shape({
      address_line1: Yup.string().required("Street line1 is required."),
      city: Yup.string().required("City is required."),
      state: Yup.object().shape({
        id: Yup.string().required("State is required"),
      }),
      country: Yup.object().shape({
        name: Yup.string().required("Country is required"),
      }),
      zipcode: Yup.string()
        .required("Zip Code is required")
        .test("is_valid_zipcode", "Please enter valid zip code", (val) => {
          if (val !== undefined) {
            var isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(val);
            if (!isValidZip) {
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        }),
    }),
    onSubmit: (values) => {
      // Do something with values
      //  addBenefit(values);
    },
  });
  const {
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    resetForm,
  } = formik || {};

  const classes = useStyles();
  return (
    <form autoComplete="false">
      <div style={{ marginTop: 32 }}>
        {header && (
          <span className="p2" style={{ fontWeight: 400 }}>
            {header}
          </span>
        )}
        {isSameActive && (
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <CheckBoxRectangle
                  checked={isChecked}
                  onChange={handleChcekrd}
                  name="checkedG"
                />
              }
              label={`Same as the ${sameTxt}`}
            />
          </Grid>
        )}
        <Grid container spacing={3} style={{ marginTop: 8 }}>
          <Grid item xs={6}>
            <InputField
              type="text"
              label="Street - line 1*"
              variant="outlined"
              fullWidth
              value={values.address_line1}
              onChange={handleChange("address_line1")}
              onBlur={() => {
                setEntityDetails(updateKey, values);
                setFieldTouched("address_line1", true);
                handleBlur("address_line1");
              }}
              error={touched.address_line1 && errors.address_line1}
              helperText={touched.address_line1 && errors.address_line1}
              disabled={isChecked}
              noMinHeight
            />
          </Grid>
          <Grid item xs={6}>
            <InputField
              type="text"
              label="Street - line 2"
              variant="outlined"
              fullWidth
              value={values.address_line2}
              onChange={handleChange("address_line2")}
              onBlur={() => {
                setEntityDetails(updateKey, values);
                setFieldTouched("address_line2", true);
                handleBlur("address_line2");
              }}
              error={touched.address_line2 && errors.address_line2}
              helperText={touched.address_line2 && errors.address_line2}
              noMinHeight
              disabled={isChecked}
            />
          </Grid>

          <Grid item xs={6}>
            <InputField
              type="text"
              label="City*"
              variant="outlined"
              fullWidth
              value={values.city}
              onChange={handleChange("city")}
              onBlur={() => {
                setEntityDetails(updateKey, values);
                setFieldTouched("city", true);
                handleBlur("city");
              }}
              error={touched.city && errors.city}
              helperText={touched.city && errors.city}
              noMinHeight
              disabled={isChecked}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="outlined" fullWidth={true}>
              <Select
                id="state"
                label="State*"
                items={states}
                fullWidth
                value={values.state.id}
                onChange={(value) => {
                  setFieldValue("state", { id: value });

                  // handleStateID(value);
                }}
                onBlur={() => {
                  setEntityDetails(updateKey, values);
                  setFieldTouched("state", true);
                  handleBlur("state");
                }}
                error={touched.state && errors.state}
                helperText={touched.state && errors.state}
                noMinHeight
                isDisabled={isChecked}
              />
              <FormHelperText className={classes.redColor}>
                {touched.state && errors?.state?.id}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <InputField
              type="number"
              label="Zip Code*"
              variant="outlined"
              fullWidth
              value={values.zipcode}
              onChange={handleChange("zipcode")}
              onBlur={() => {
                setEntityDetails(updateKey, values);
                setFieldTouched("zipcode", true);
                handleBlur("zipcode");
                updateLocation(getAddress(values));
              }}
              error={touched.zipcode && errors.zipcode}
              helperText={touched.zipcode && errors.zipcode}
              noMinHeight
              disabled={isChecked}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="outlined" fullWidth={true}>
              <Select
                id="country"
                label="Country*"
                items={countries}
                fullWidth
                value={values.country.name}
                onChange={(value) => {
                  setFieldValue("country", { name: value });
                  // handleStateID(value);
                }}
                onBlur={() => {
                  setEntityDetails(updateKey, values);
                  setFieldTouched("country", true);
                  handleBlur("country");
                }}
                error={touched.country && errors.country}
                helperText={touched.country && errors.country}
                noMinHeight
                isDisabled={isChecked}
              />
              <FormHelperText className={classes.redColor}>
                {touched.country && errors?.country?.name}
              </FormHelperText>
            </FormControl>
          </Grid>

          {showLocate && (
            <Grid container spacing={3}>
              <Grid item xs={12} className={classes.addressBase}>
                <div className="row-between">
                  <PrimaryButton
                    onClick={() => updateLocation(getAddress(values))}
                  >
                    Locate
                  </PrimaryButton>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className={classes.zoomStyle}>
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: "AIzaSyCBtxbp-SHfs47f6diRNcfYKRsmxzn0qPg",
                      libraries: ["places"],
                    }}
                    center={{
                      lat: location.latitude,
                      lng: location.longitude,
                    }}
                    defaultZoom={14}
                  >
                    <img
                      src={LocationIcon}
                      lat={location.latitude}
                      lng={location.longitude}
                      alt="map view"
                    />
                  </GoogleMapReact>
                </div>
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
    </form>
  );
};
export default AddressComponent;
const useStyles = makeStyles((theme) => ({
  zoomStyle: {
    height: 400,
    width: "100%",
  },
  redColor: {
    color: "red",
  },
}));
