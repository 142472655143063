import React from "react";
import UploadIcon from "../../assets/icons/upload.png";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  csvButton: {
    height: "48px",
    width: "168px",
    border: "0.8px solid #edecf5",
    "& .MuiButton-label": {
      color: "#434966",
      fontWeight: 600,
      fontFamily: "Manrope",
      fontSize: "13px",
    },
  },
});

const CSVButton = ({ onClick, border, disabled, title = "Export to CSV" }) => {
  const classes = useStyles();
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      className={disabled ? classes.csvButton : "csv-btn"}
      variant="contained"
      style={{
        boxShadow: "none",
        border: border === true ? "1px solid #EDECF5" : "none",
      }}
    >
      <img src={UploadIcon} alt="export" style={{ marginRight: "5px" }} />{" "}
      {title}
    </Button>
  );
};

export default CSVButton;
